
  .popin {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    overflow: auto;
    padding: 20px;
    display: flex;
    z-index: 10000;
  }
  .marketing-popin-inner {
    position: relative;
    background: white;
    border-radius: 24px;
    overflow: hidden;
    border: 2px solid grey;
    margin: auto;
    text-align: left;

    .col-md-6{
        padding: 45px 15px 20px 15px;
        text-align: center;
        
        a{
            font-size: 14px;
            padding: 7px 14px;
        } 

        @media (min-width: $screen-xs-min) {
            a{
                font-size:16px;
                padding: 10px 20px;
            } 
        } 
        @media (min-width: $screen-sm-min) {
            text-align: left;
            padding: 30px 40px 40px 40px;
            a{
                font-size:20px;
                padding: 16px 24px;
            } 
        }
        
    }

    .popin-close {
        position: absolute;
        font-size: 0;
        text-decoration: none;
        top: 20px;
        right: 20px;
        z-index: 12;
      }
      .popin-close::before {
        content: "×";
        color: white;
        border-radius: 50%;
        border: 2px solid white;
        cursor:pointer;
        font-size: 51px;
        line-height: 33px;
        font-weight: 300;
        width: 40px;
        height: 40px;
      }
  }


