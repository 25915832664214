
.bg-primary{
    background: $color-primary;
}

.bg-secondary{
    background: $color-secondary;
}

.bg-background {
    background: $color-background;
}

.bg-background-beta {
    background: $color-background-beta;
}

/*
.shape-pink-left-right{
    position: relative;
    &::before{
        content:  url('../img/background/shape-pink.svg');
        position: absolute;
        z-index: -1;
        left: -540px;
        bottom: -200px;
    }
    
}*/


.shape-pink-left {
    @media (min-width: $screen-md-min) {
        position: relative;
        overflow: hidden;

        &::before{
            content:  url('../img/background/shape-pink.svg');
            position: absolute;
            z-index: -1;
            left: -540px;
            top: 0px;
        }
    }
}

.shape-pink-right {
    position: relative;
    overflow: hidden;

    &::before{
        content:  url('../img/background/shape-pink.svg');
        position: absolute;
        z-index: -1;
        right: -540px;
        top: -200px;
    }
}

.shape-green-right {
    position: relative;
    &::before{
        content:  url('../img/background/shape-green.svg');
        position: absolute;
        z-index: -1;
        right: -540px;
    }
}

.shape-green-left-right {
    position: relative;
    //overflow: hidden;

    &::before {
        content:  url('../img/background/shape-green.svg');
        position: absolute;
        z-index: -1;
        right: -740px;
        top: -470px;
        transform-origin: center;
        transform: scale(0.75);

        @media (min-width: $screen-md-min) {
            right: -540px;
            top: -250px;
            transform: none;
        }
    }
    &::after {
        content:  url('../img/background/shape-green.svg');
        position: absolute;
        z-index: -1;
        left: -1011px;
        bottom: -410px;
        transform-origin: center;
        transform: scale(0.75);

        
        @media (min-width: $screen-md-min) {
            left: -811px;
            bottom: -410px;
            transform: none;
        }
    }
}


.shape-pink-left-right {
    position: relative;
    //overflow: hidden;

    &::before {
        content:  url('../img/background/shape-pink.svg');
        position: absolute;
        z-index: -1;
        right: -740px;
        top: -470px;
        transform-origin: center;
        transform: scale(0.75);

        @media (min-width: $screen-md-min) {
            right: -690px;
            top: -650px;
            transform: none;
        }
    }
    &::after {
        content:  url('../img/background/shape-pink.svg');
        position: absolute;
        z-index: -1;
        left: -712px;
        top: 221px;
        transform-origin: center;
        transform: scale(0.75);

        
        @media (min-width: $screen-md-min) {
            left: -301px;
            top: 221px;
            transform: none;
        }
    }
}

#faq .shape-pink-top-top {
    &::after {
        @media (min-width: $screen-md-min) {
            left: -551px;
            top: -700px;
            transform: none;
        }
    }
}
.shape-pink-top-top {
    position: relative;
    overflow-x: hidden;
    overflow-y: visible;

    &::before {
        content:  url('../img/background/shape-pink.svg');
        position: absolute;
        z-index: -1;
        right: -740px;
        top: -70px;
        transform-origin: center;
        transform: scale(0.75);

        @media (min-width: $screen-md-min) {
            right: -575px;
            top: -710px;
            transform: none;
        }
    }
    &::after {
        content:  url('../img/background/shape-pink.svg');
        position: absolute;
        z-index: -1;
        left: -712px;
        top: -0px;
        transform-origin: center;
        transform: scale(0.75);

        
        @media (min-width: $screen-md-min) {
            left: -760px;
            top: -290px;
            transform: none;
        }
    }
}

.shape-dark-green-top-top {
    position: relative;
    overflow-x: hidden;
    overflow-y: visible;

    &::before {
        content:  url('../img/background/shape-green.svg');
        position: absolute;
        z-index: -1;
        right: -670px;
        top: -570px;
        transform-origin: center;
        transform: scale(0.75);

        @media (min-width: $screen-md-min) {
            right: -575px;
            top: -730px;
            transform: none;
        }
    }
    &::after {
        content:  url('../img/background/shape-dark-green.svg');
        position: absolute;
        z-index: -1;
        left: -770px;
        top: -140px;
        transform-origin: center;
        transform: scale(0.75);


        @media (min-width: $screen-md-min) {
            left: -510px;
            top: -465px;
            transform: none;
        }
    }
}


.shape-green-top-top {
    position: relative;
    overflow-x: hidden;
    overflow-y: visible;

    &::before {
        content:  url('../img/background/shape-green.svg');
        position: absolute;
        z-index: -1;
        right: -670px;
        top: -570px;
        transform-origin: center;
        transform: scale(0.75);

        @media (min-width: $screen-md-min) {
            right: -575px;
            top: -730px;
            transform: none;
        }
    }
    &::after {
        content:  url('../img/background/shape-green.svg');
        position: absolute;
        z-index: -1;
        left: -770px;
        top: -140px;
        transform-origin: center;
        transform: scale(0.75);

        
        @media (min-width: $screen-md-min) {
            left: -660px;
            top: -790px;
            transform: none;
        }
    }
}

.shape-bike {
    background: url('../img/background/shape-bike.svg') no-repeat center, $color-font-dark ;
}