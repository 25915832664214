

$logo_width: 174px;

#header {
    height: 180px;

    @media (max-width: 768px) {
        height: 100px;
        background-color: $color-font-dark;
    }
    .container-fluid {
        @media (max-width: 768px) {
            padding-left: unset;
            padding-right: unset;
        }
    }
    .header-top {
        color: $color-font-dark;
        background-color: $color-background;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        a {
            color: white;
            font-size: 1.5rem;
            font-weight: 700;
            word-wrap: break-word;
            margin-left: 2rem;
            height: 100%;
            display: flex;
            align-items: center;
        }
        @media (max-width: $screen-sm) {
            background-color: #ffffff;
            display: block;
            text-align: center;
            align-items: center;
            padding-top: 1rem;
            padding-bottom: 1rem;
            text-wrap-style: balance;
            font-size: 1.5rem;
            line-height: 2rem;
            height: unset;
        }
    }
    a#blockcart-header {
        color: #ffffff!important;
        &:hover {
            background: $color-primary-beta!important;
        }
    }

    .navbar {

        background-color:white;
        @media (max-width: 768px) {
            background-color: $color-font-dark;
        }

        .menu-desktop {
            width: 1440px;
            display: none;
            height: 125px;

            @media (min-width: $screen-md-min) {
                display: flex;
            }

            ul#header-navbar-right-nav {
                display: flex !important;
                align-items: center;
                width: 100%;
                justify-content: space-evenly;
    
                li:not(.main-shop-link) {
                    float: none;
                    a {
                        font-size: 16px;
                        line-height:24px;
                        color: $color-font-dark;
                        font-weight: bold;
                        display: flex;
                        align-items: center;

                        svg{
                            margin-right: 12px;
                        }


                        &:focus{
                            background-color: transparent;
                        }

                        &.active{
                            color: $color-secondary;

                            svg path{
                                fill: $color-secondary
                            }
                        }
    

                        &:hover{
                            background-color: transparent;
                            color: $color-primary;

                            svg path{
                                fill: $color-primary
                            }
                        }
    
                        &.shop-link{
                            color:$color-font-light;
                        }

                        img {
                            margin-right: 12px;
                        }
                    }
                }
    
                li.main-shop-link {
                    //margin-left: auto;
                    a {
                        color:$color-font-light;
                    }
                }
                a.brand {
                    padding-top: 60px;
                }
            }
        }

        .menu-mobile {

            height: 80px;
            @media (max-width: 768px) {
                position: relative;
            }
            @media (min-width: $screen-md-min) {
                display:none;
            }

            .navbar-brand {
                position: absolute;
                top: 0;
                left: 55px;
                width: $logo_width;
                transform: scale(0.8);
                transform-origin: top center;

            }

            .main-shop-link-nav {
                position: absolute;
                top: 14px;
                right: 15px;
            }

            .menu {
                position: absolute;
                top: 16px;
                left: 15px;
                height: 46px;
                width: 46px;
            }
            
            .menu-link {
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 1002;
                background-color: $color-primary;
                border-radius: 50px;
                &:hover{
                    background-color: $color-primary-beta;
                }
            }
            
            .menu-icon {
                position: absolute;
                width: 20px;
                height: 14px;
                margin: auto;
                left: 0;
                top: 0;
                right: 0;
                bottom: 1px;
            }
            
            /* ------------- */
            .menu-line {
                background-color: $color-font-light;
                height: 2px;
                width: 100%;
                border-radius: 2px;
                position: absolute;
                left: 0;
                transition: all 0.25s ease-in-out;
            }
            .menu-line-1 {
                top: 2px;
            }
            .menu-line-3 {
                bottom: 1px;
            }

            .menu.open {
                .menu-link {
                    .menu-line-1 {
                        transform: translateY(5px) translateY(-50%) rotate(-45deg);
                    }
                    .menu-line-3 {
                        transform: translateY(-6px) translateY(50%) rotate(45deg);
                    }
                }
            }
            
            /* ------------- */
            .menu-circle {
                background-color: $color-font-dark;
                width: 100%;
                height: 100%;
                position: absolute;
                border-radius: 50%;
                transform: scale(0.99);
                z-index: 1000;
                transition: transform 0.3s ease-in-out;
            }
            .menu:hover .menu-circle {
                //transform: scale(1.5);
            }
            .menu.open .menu-circle {
                
                transform: scale(30);

                @media (min-width: $screen-sm-min) {
                    transform: scale(40);
                }

            }
            
            /* ------------- */
            .menu-overlay {
                //background-color: $color-font-dark;
                height: 100%;
                width: 100%;
                padding-left: 15px;
                position: fixed;;
                transition: opacity 0.2s ease-in-out;
                transition-delay: 0.1s;
                z-index: 1001;
                opacity: 1;
                visibility: hidden;

                border-bottom-right-radius: 50%;

                .navbar-brand {
                    position: absolute;
                    top: 4px;
                    left: 54px;
                    width: $logo_width;
                    transform: scale(0.619);
                    transform-origin: top center;

                    transition: opacity 0.2s ease-in-out;
                    opacity: 0;
                    visibility: hidden;

                }

                ul#mobile-navbar {
                    
                    transition: opacity 0.2s ease-in-out;
                    transition-delay: 0.1s;
                    opacity: 0;
                    visibility: hidden;

                    position: absolute;
                    width: 200px;
                    top: 155px;
                    left: 105px;
                    display: flex;
                    flex-direction: column;
                    li {
                        margin-bottom: 20px;
                        a {
                            font-size: 16px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.6;
                            letter-spacing: normal;
                            color: $color-font-light;

                            &.active{
                                color: $color-tertiary;
                            }

                            &:hover {
                                color: $color-tertiary;
                            }
                        }
                        &.main-shop-link{
                            margin-left: -20px;
                            margin-top: 10px;
                        }
                    }
                }
            }

            .menu-overlay.open {
                opacity: 1;
                visibility: visible;
                .navbar-brand {
                    opacity: 1;
                    visibility: visible;
                }
                ul#mobile-navbar{
                    opacity: 1;
                    visibility: visible;
                }
            }
            
            /* ------------- */
            .info {
                text-align: center;
                position: absolute;
                top: 50%;
                font-size:60px;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .overlay-info {
                text-align: center;
                color: #111825;
            }
        }
    }
}

.menu-margin {
    //margin-top: 80px;

    @media (min-width: $screen-sm-min) {
        margin-top: 96px;
    }
}