@use "text" as text;

.btn {
    padding: 16px 24px;
    border-radius: 32px;
    font-family: $main-font;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center; 
    color: $color-font-dark;
    outline: none !important;
    border: none !important;
    box-shadow: none;

    span{
        z-index: 1;
        position: relative;
        display: flex;
        align-items: center;
    }

    /*img{
        vertical-align: bottom;
        margin-right: 6px;
    }*/

    img.next{
        margin-left: 15px;
        margin-right: 0;
        vertical-align: middle;
    }

    &:active{
        box-shadow: none;
    }

    &:focus, &:active:focus, &:active:hover {
        outline: none;
    }

    &:hover {
        color: $color-primary;
    }

    &.btn-text-light {
        color: $color-font-light;
    }

    &.btn-text-primary {
        color: $color-primary;

        &:hover {
            color: $color-primary-beta;
        }
    }

    &.btn-underline {
        text-decoration: underline;
        padding-right: 0;
        padding-left: 0;
        img, svg{
            vertical-align: middle;
            margin-right: 10px;
        }

        &:hover{
            svg path {
                fill: $color-primary,
            }
        }
    }
    
    &.btn-primary, &.btn-primary-beta, &.btn-outline-primary, &.btn-secondary, &.btn-outline-secondary, &.btn-light {
        
        outline: 0;
        position: relative;
        overflow: hidden;
        transform-origin: center center;
    

        &:hover{
            .click-span{
                visibility: visible;
            }
        }

        .click-span {
            border-radius: 50%;
            position: absolute;
            z-index:0;
            transform: scale(0);
            animation: ripple 0.6s linear;
            width: 20px;
            height: 20px;
            visibility: hidden;
         }
    
         @keyframes ripple{
            0% {
               transform: scale(1);
               opacity: 1;
             }
             90% {
               transform: scale(30);
               opacity: 1;
             }
             100%{
                transform: scale(30);
                opacity: 0;
             }
        }

        &.btn-primary {
            .click-span {
                background-color: $color-primary;
            }
        }

        &.btn-primary-beta {
            .click-span {
                background-color: $color-primary-beta;
            }
        }

        &.btn-outline-primary {
            .click-span {
                background-color: $color-primary;
            }
        }

        &.btn-secondary {
            .click-span {
                background-color: $color-secondary;
            }
        }

        &.btn-light {
            .click-span {
                background-color: $color-font-light;
            }
        }
    }
    
    &.btn-primary {
        color: $color-font-light;
        background-color: $color-primary;

        &:hover {
            background-color: $color-primary-beta;
            
        }
    }

    &.btn-primary-beta {
        color: $color-font-light;
        background-color: $color-primary-beta;

        &:hover {
            background-color: $color-primary;
        }
    }

    &.btn-outline-primary {
        background-color: transparent;
        color: $color-primary;
        box-shadow: 0px 0px 0px 1px $color-primary inset;

        &:hover {
            background-color: $color-primary-beta;
            color: $color-font-light;
            svg path {
                fill: $color-font-light;
            }
        }

        &.svg-right{
            svg{
                margin-left: 8px;
            }
        }

        &.svg-left{
            svg{
                margin-right: 8px;
            }
        }
    }

    &.btn-secondary {
        color: $color-font-light;
        background-color: $color-secondary;
        &:hover {
            background-color: $color-secondary-beta;
        }
    }

    &.btn-outline-secondary {
        background-color: transparent;
        color: $color-secondary;
        box-shadow: 0px 0px 0px 1px $color-secondary inset;

        &:hover {
            background-color: $color-secondary-beta;
            color: $color-font-light;
            svg path {
                fill: $color-font-light;
            }
        }
    }

    &.btn-tertiary-arrow {
        width:48px;
        height:48px;
        display:flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: $color-tertiary;
        padding: 0;

        &::before{
            width: 24px;
            height: 24px;
            content: url('../img/slider/ic-next-white.svg');
        }

        &:hover{
            background-color: $color-tertiary-beta;
        }
    }

    &.btn-tertiary-arrow {
        width:48px;
        height:48px;
        display:flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: $color-primary;
        padding: 0;

        &::before{
            width: 24px;
            height: 24px;
            content: url('../img/slider/ic-next-white.svg');
        }

        &:hover{
            background-color: $color-primary-beta;
        }
    }

    &.btn-grey {
        color: $color-font-medium;
        background-color: $color-grey;
        &:hover {
            filter:brightness(0.9);
        }
    }


    &.btn-round {
        width:48px;
        height:48px;
        display:flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 0;

        &.btn-lg{
            width: 64px;
            height: 64px;
        }
    }


    &.btn-light {
        background-color: $color-font-light;
        color: $color-primary;
        &:hover {
            background-color: $color-background;
        }
        &--dark-font {
            color: $color-font-dark;
        }
    }

    &.btn-small{
        font-size: 16px;
        padding: 12px 20px;
    }

    &.btn-compact{
        font-size: 14px;
        padding: 9px 16px;
    }

    /*
    &.btn-arrow {
        span::after {
            width:24px;
            height:24px;
            position: relative;
            left: 8px;
            content: url('../img/slider/ic-next-white.svg');
        }
    }*/

    .fa {
        font-size: 28px;
        margin-right: 16px;
        vertical-align: text-bottom;
    }
}

.btn-switch{
    height: 56px;
    padding: 4px;
    display: inline-flex;
    border-radius: 30px;

    .btn-option {
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;    
        border-radius: 24px;
        padding: 12px 20px;
        cursor:pointer;
        &:first-of-type{
            margin-right: 8px;
        }
    }
}

.btn-switch-big {
    @media (min-width: $screen-md-min) {
        height: 80px;
        padding: 8px;
        border-radius: 40px;
        .btn-option {
            padding: 16px 63px;
            border-radius: 32px;
            @include text.font-button();
        }
    }
}


.btn-switch-secondary {
    background-color: $color-secondary;
    .btn-option {
        color: $color-font-light;
        &.active {
            color: $color-secondary;
            background-color: $color-font-light;
        }
    }
}

.btn-switch-primary {
    background-color: $color-primary;
    .btn-option {
        color: $color-font-light;
        &.active {
            color: $color-primary;
            background-color: $color-font-light;
        }
    }
}


.prev {
    display: flex;
    align-items: center;
    svg{
        margin-right: 12px;
    }

    &.font-light{
        svg path{
            fill: $color-font-light;
        }
    }

    &:hover{
        svg path{
            fill: $color-primary;
        }
    }
}



.btn-event {
    position:relative;
    overflow: visible !important;

   .ace_dd{

        position: absolute;
        top: calc(100%);
        left: 0;
        z-index: 2;
        box-shadow: 0 0 10px -6px;
        padding: 20px;
        border-radius: 16px;
        box-shadow: 0 2px 10px 0 rgb(0 0 0 / 6%);
        background-color: #fff;
        display: none;

        &.active{
            display: block;
        }
        
        .ace_opt{
                font-size: 16px;
                line-height: 24px;
                font-weight: 300;
                min-height: 20px;
                display: block;
                padding: 10px;
                color: #16314b;
                cursor: pointer;
                text-align: left;
        }
    }
}