.faq-group {

    margin-bottom: 40px;

    .faq-question {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        cursor:pointer;

        &::after {
            position:relative;
            top: -5px;
            border-radius: 20px;
            margin-left:15px;
            min-width: 40px;
            height: 40px;
            content: ' ';
            background-image: url('../img/faq/ic-add.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-color: $color-background-beta;
            cursor:pointer;
        }

        &.open{
            &::after{
                background-image: url('../img/faq/ic-remove.svg');
            }
        }
    }

    .faq-answer{
        display: none;
        transform: scaleY(0);
        transition: opacity 0.2s ease;
        padding-right: 35px;
        &.open {
            display: block;
            animation: growDown2 300ms ease-in-out forwards;
            transform-origin: top center
        }

        @keyframes growDown2 {
            0% {
                transform: scaleY(0)
            }
            80% {
                transform: scaleY(1.1)
            }
            100% {
                transform: scaleY(1)
            }
        }
    }
}