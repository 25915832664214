#ourapproach {

  #container-fluid-titre {
    background-color: $color-font-dark;
    padding-top: 85px;
    padding-bottom: 325px;
    color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    &:after{
      content: "";
      display: block;
      width: 1440px;
      height: 275px;
      background-image: url("../img/approach/illustrations.svg");
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      bottom: 0;
      @media (max-width: 769px) {
        background-image: url("../img/approach/illustrations-mobile.svg");
        width: 314px;
        height: 194px;
      }
    }
    h1 {
      text-wrap: balance;
      font-family: $alt-font;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 55px;
      text-align: center;
      max-width: 800px;
      position: relative;
      @media (max-width: 769px) {
        max-width: unset;
        font-size: 28px;
        line-height: 35px;
      }

      &:before {
        content: "";
        display: block;
        width: 35px;
        height: 25px;
        background-image: url("../img/approach/trait.svg");
        position: absolute;
        top: -15px;
        right: 95px;
        @media (max-width: 769px) {
          display: none;
        }
      }
      span:not(.soulignement) {
        display: block;
        position: relative;
      }
      span.soulignement {
        &:after {
          content: "";
          display: block;
          width: 285px;
          height: 6px;
          background-image: url(../img/approach/souligne-285.svg);
          background-repeat: no-repeat;
          position: absolute;
          margin-left: 45px;
        }
      }
      span.soulignement {
        position: relative;
        &:after {
          @media (max-width: 769px) {
            bottom: 3px;
            background-image: url(../img/approach/souligne-196.svg);
            width: 200px;
            margin-left: unset;
            right: -4px;
          }
        }
      }

    }

    p {
      margin: unset;
      margin-top: 20px;
      max-width: 895px;
      text-align: center;
    }

    @media (max-width: 768px) {
      padding-top: 125px;
    }
  }

  h2 {
    color: $color-secondary;
    text-align: center;
    font-family: $main-font;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 16px;
    }
  }
  h3, h4 {
    color: $color-font-dark;
    text-align: center;
    font-family: $alt-font;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
    margin-top: 17px;
    position: relative;
    @media (max-width: 768px) {
      font-size: 22px;
      line-height: 25px;
    }
  }
  p {
    @media (max-width: 768px) {
      font-size: 16px!important;
      line-height: normal!important;
    }
  }

  #container-fluid-cercle {
    background-color: $color-background;
    padding-top: 40px;
    padding-bottom: 145px;
    position: relative;
    @media (max-width: 768px) {
      padding-bottom: 75px;
    }
    &:after {
      content: "";
      width: 65px;
      height: 65px;
      background-image: url(../img/approach/green-bottom-arrow.svg);
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      bottom: -30px;
      z-index: 1000;
      margin: 0 auto;
      left: calc(50% - 37px);
    }


    .tiles {
      display: flex;
      justify-content: center;
      margin-top: 100px;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 55px;
      @media (max-width: 769px) {
        gap: 85px
      }
      .tile {
        width: 390px;
        height: 286px;
        border: 2px solid $color-font-dark;
        border-radius: 25px;
        position: relative;
        @media (max-width: 769px) {
          width: 340px;
        }
        &:before {
          content: "";
          display: block;
          width: 55%;
          height: 4px;
          background-color: $color-background;
          margin-top: -2px;
          margin-right: auto;
          margin-left: auto;
        }
        .tile-title {
          padding-top: 15px;
          text-align: center;
          color: $color-secondary;
          font-family: $main-font;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: 25px;
          position: relative;
        }
        .tile-text {
          text-align: center;
          margin-top: 13px;
          color: $color-font-dark;
          font-family: $main-font;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-wrap: balance;
          position: relative;
        }
        img#bicyclette {
          display: flex;
          margin: 0 auto;
          margin-top: -23px;
          @media (max-width: 768px) {
            width: 90%;

          }
        }
        img#pain-frais {
          display: flex;
          margin-top: -60px;
          margin-left: -44px;
          @media (max-width: 768px) {
            width: 90%;
            margin-left: -23px;
          }
        }
        img#commander-en-ligne {
          display: flex;
          margin: 0 auto;
          margin-top: -75px;
          margin-right: -50px;
          @media (max-width: 768px) {
            width: 90%;
            margin-top: -60px;
            margin-right: -21px;
          }
        }
      }
    }
  }

  #container-fluid-etape1 {
    padding-top: 60px;
    background-color: #ffffff;
    padding-bottom: 100px;

    position: relative;
    &:after {
      content: "";
      width: 65px;
      height: 65px;
      background-image: url(../img/approach/green-bottom-arrow.svg);
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      bottom: -30px;
      z-index: 1000;
      margin: 0 auto;
      left: calc(50% - 37px);
    }

    h3 {
      position: relative;
      max-width: 610px;
      margin: 0 auto;
      span {
        position: relative;
        &.souligne:after {
          content: "";
          width: 166px;
          height: 6px;
          background-image: url(../img/approach/souligne-166.svg);
          background-repeat: no-repeat;
          position: absolute;
          display: block;
          right: 0;
          bottom: 2px;
        }
        @media screen and (max-width: 768px) {
          display: block;
          &.souligne:after {
            left: 41.5%;
          }
        }
      }
    }

    .circles {
      margin-top: 75px;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 35px;
      @media screen and (max-width: 768px) {
        gap: 75px;
      }
      .circle {
        @media (max-width: 769px) {
          max-width: 350px;
          width: 100%;
          border-radius: 50px;
          border: 3px solid var(--bleu, #16314B);
          padding: 15px;
          text-align: center;
        }
        &:nth-child(1) {
          .circle-title {
            &:before {
              content: "";
              display: block;
              width: 35px;
              height: 25px;
              background-image: url(../img/approach/traits-dispatche.svg);
              background-repeat: no-repeat;
              position: absolute;
              top: -20px;
              left: 50px;
            }
          }
        }
        &:nth-child(2) {
          .circle-text {
            &:after {
              content: "";
              display: block;
              width: 10px;
              height: 10px;
              background-image: url(../img/approach/coeur.svg);
              background-repeat: no-repeat;
              position: absolute;
              bottom: 10px;
              right: 45px;
            }
          }
        }
        &:nth-child(4) {
          .circle-title {
            &:after {
              content: "";
              display: block;
              width: 84px;
              height: 5px;
              background-image: url(../img/approach/souligne-84.svg);
              background-repeat: no-repeat;
              position: absolute;
              bottom: 2px;
              right: 90px;
            }
          }
        }
        position: relative;
        span#vos-commandes {
          position: absolute;
          right: -90px;
          top: -35px;
          color: $color-secondary;
          text-align: center;
          font-family: $alt-font;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        span#la-decoration {
          position: absolute;
          left: -82px;
          bottom: 95px;
          color: $color-secondary;
          text-align: center;
          font-family: $alt-font;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        &-title {
          margin-top: 31px;
          color: $color-font-dark;
          text-align: center;
          font-family: $alt-font;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: 45px;
          position: relative;
        }
        &-text {
          color: #000;
          text-align: center;
          font-family: $main-font;
          font-size: 22px;
          font-style: normal;
          font-weight: 400;
          line-height: 25px;
          margin-top: 10px;
          position: relative;
        }
      }
    }
  }

  #container-fluid-etape2 {
    padding-top: 60px;
    padding-bottom: 100px;
    background-color: $color-background;
    position: relative;
    &:after {
      content: "";
      width: 65px;
      height: 65px;
      background-image: url(../img/approach/green-bottom-arrow.svg);
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      bottom: -30px;
      z-index: 1000;
      margin: 0 auto;
      left: calc(50% - 37px);
    }
    span.souligne {
      @media screen and (max-width: 768px) {
        display: block;
      }
    }
    .items {
      //@extend .circles;
      margin-top: 100px;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 75px;
      @media screen and (max-width: 768px) {
        gap: 120px;
      }
     .item {
       position: relative;
       span#produit-frais {
         top: -45px;
         left: 50px;
         @media (max-width: 768px) {
           top: -25px;
           left: -10px;
         }
       }
       span#super-accueil {
         top: 100px;
         max-width: 126px;
         left: -35px;
         @media (max-width: 768px) {
           top: 69px;
           max-width: 110px;
           left: -35px;
         }
       }
       span#bonne-degustation {
         right: 30px;
         top: -35px;
         @media (max-width: 768px) {
           right: -10px;
           top: -10px;
         }
       }
       > span {
         color: $color-secondary;
         text-align: center;
         font-family: $alt-font;
         font-size: 20px;
         font-style: normal;
         font-weight: 400;
         line-height: normal;
         position: absolute;
         @media (max-width: 768px) {
           font-size: 14px;
           line-height: 16px;
         }
       }
       > img {
         max-height: 250px;
         display: flex;
         margin: 0 auto;
         @media (max-width: 768px) {
           max-height: 200px;
         }

       }

       &:nth-child(2) {
         img {
           margin-top: -47px;
         }
       }
       &:nth-child(3) {
         img {
           margin-top: 11px;
         }
       }
        &-title {
         //@extend .circle-title;
          margin-top: 31px;
          color: $color-font-dark;
          text-align: center;
          font-family: $alt-font;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: 45px;
          position: relative;
          @media (max-width: 768px) {
            font-size: 22px;
            line-height: 29px;
          }
        }
        &-text {
         //@extend .circle-text;
          color: #000;
          text-align: center;
          font-family: $main-font;
          font-size: 22px;
          font-style: normal;
          font-weight: 400;
          line-height: 25px;
          margin-top: 10px;
          position: relative;
          span {
            display: block;
          }
          @media (max-width: 768px) {
            font-size: 18px;
            line-height: 22px;
          }
        }
     }
    }
  }

  #container-fluid-etape3 {
    padding-top: 60px;
    padding-bottom: 15px;
    background-color: #ffffff;
    position: relative;
    @media (max-width: 768px) {
      padding-bottom: 0px;
      margin-left: -15px;
      margin-right: -15px;
    }
    span.numero {
      width: 34px;
      height: 34px;
      background-color: $color-primary;
      color: #ffffff;
      font-size: 18px;
      text-align: center;
      border-radius: 50%;
      display: inline-block;
      font-family: $alt-font;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
    }
    span.souligne {
      position: relative;
      &:after {
        content: "";
        display: block;
        width: 201px;
        height: 6px;
        background-image: url(../img/approach/souligne-196.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        bottom: 2px;
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      @media screen and (max-width: 768px) {
        display: block;
      }
    }
    div.routes {
      width: 770px;
      background-image: url(../img/approach/chemin.svg);
      background-repeat: no-repeat;
      padding-bottom: 120px;
      margin: 0 auto;
      margin-top: 40px;
      @media (max-width: 768px) {
        background-image: url(../img/approach/chemin-mobile.svg);
        max-width: 100%;
        background-position-x: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 50px;
      }
      .portion {
        padding-top: 42px;
        padding-bottom: 33px;
        @media (max-width: 768px) {
          padding-top: 20px;
          padding-bottom: 15px;
          max-width: 340px;
        }
        &-text {
          color: $color-font-dark;
          text-align: center;
          font-family: $main-font;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: 25px;
          span.orange {
            color: $color-secondary;
          }
          @media (max-width: 768px) {
            font-size: 16px;
            padding-left: 15px;
            padding-right: 15px;
          }
        }
      }
    }
    &:before {
      content: "";
      width: 350px;
      height: 491px;
      background-image: url(../img/approach/recuperation-bg-droite.svg);
      background-repeat: no-repeat;
      position: absolute;
      bottom: 0;
      right: 0;
      @media (max-width: 768px) {
        display: none;
      }
    }
    &:after {
      content: "";
      width: 675px;
      height: 491px;
      background-image: url(../img/approach/recuperation-bg-gauche.svg);
      background-repeat: no-repeat;
      position: absolute;
      bottom: -6px;
      left: 0;
      @media (max-width: 768px) {
        background-image: url(../img/approach/recuperation-bg-mobile.svg);
        height: 246px;
        width: 382px;
        position: relative;
      }
    }
  }
  @keyframes scrollText {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

  .scrolling-text {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
  }

  .scrolling-text span {
    display: inline-block;
    padding-left: 100%;
    animation: scrollText 15s linear infinite;
    > img {
      margin-left: 7px;
    }
  }

  #bandeau-vert {
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: #63BA9E;
    color: #ffffff;
    text-align: center;
    font-family: $main-font;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    position: relative;
    > div {
      position: relative;
    }
  }

  #avantages {
    min-height: 928px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
    position: relative;

    img.pastille {
      position: absolute;
      left: calc(50% - 57px);
      top: 79px;
    }
    div.left {
      background-image: url(../img/approach/avantage-bg-gauche.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      padding-left: -15px;
      width: 50%;
      @media (max-width: 768px) {
        height: 416px;
        width: 100%;
      }
    }
    div.right {
      padding-top: 65px;
      padding-bottom: 100px;
      width: 50%;
      background-color: $color-font-dark;
      @media (max-width: 768px) {
        width: 100%;
        padding-top: 25px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 50px;
      }
      h3 {
        color: #ffffff;
        position: relative;
        max-width: 400px;
        margin: 0 auto;
        margin-top: 20px;
        span.souligne {
          display: block;
        }
        &:before {
          content: "";
          background-image: url(../img/approach/avantage-trait.svg);
          background-repeat: no-repeat;
          background-size: cover;
          display: inline-block;
          width: 24px;
          height: 26px;
          top: -12px;
          left: 0;
          position: absolute;
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
      .avantages-list {
        margin-top: 60px;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 40px;
        position: relative;
        .avantage {
          font-family: $main-font;
          font-style: normal;
          border-bottom: 2px solid #ffffff;
          padding-bottom: 24px;
          position: relative;
          width: 550px;
          @media (max-width: 768px) {
            max-width: 100%;
            padding-left: 10px;
            padding-right: 10px;
          }
          &-title {
            font-size: 22px;
            font-weight: 600;
            line-height: 25px;
            display: flex;
            align-items: center;
            @media (max-width: 768px) {
              font-size: 18px;
              line-height: 22px;
              padding-left: 22px;
            }
            &:before {
              content: "";
              background-image: url(../img/approach/yellow-point.svg);
              background-repeat: no-repeat;
              background-size: cover;
              height: 12px;
              width: 12px;
              display: inline-block;
              margin-right: 10px;
              @media (max-width: 768px) {
                position: absolute;
                top: 5px;
                left: 10px;
                margin-right: unset;
              }
            }
          }
          &-text {
            font-size: 18px;
            font-weight: 400;
            line-height: normal;
            padding-left: 22px;
            @media (max-width: 768px) {
              font-size: 16px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }

  #devise {
    padding-top: 85px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 1440px;
    position: relative;
    @media (max-width: 768px) {
      width: 100%;
      padding-top: 25px;
      padding-bottom: 200px;
    }
    &:before {
      content: "";
      background-image: url(../img/approach/bg-devise.svg);
      background-repeat: no-repeat;
      position: absolute;
      bottom: 0px;
      right: 85px;
      width: 225px;
      height: 170px;
    }
    &:after {
      content: "";
      background-image: url(../img/approach/etoile.svg);
      background-repeat: no-repeat;
      position: absolute;
      left: 170px;
      top: 93px;
      width: 20px;
      height: 20px;
      @media (max-width: 768px) {
        background-image: unset;
      }
    }
    h3 {
      &:after {
        content: "";
        background-image: url(../img/approach/souligne-129.svg);
        background-repeat: no-repeat;
        position: absolute;
        height: 10px;
        width: 170px;
        bottom: -2px;
        right: -40px;
        @media (max-width: 768px) {
          bottom: -7px;
          right: -44px;
        }
      }
    }
    p {
      text-align: center;
      margin-top: 15px;
      max-width: 910px;
      font-family: $main-font;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media (max-width: 768px) {
        font-size: 16px;
      }
      span {
        font-weight: 600;
      }
    }
    a {
      margin-top: 39px;
    }
  }

  #faq {
    background-color: $color-background;
    padding-bottom: 115px;
    padding-top: 81px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    @media (max-width: 768px) {
      padding-top: 50px;
      h3:before {
        content: "";
        bottom: -4px;
        background-image: url(../img/approach/souligne-196.svg);
        display: block;
        width: 200px;
        height: 5px;
        right: -4px;
        position: absolute;
      }
    }

    &:before {
      content: "";
      background-image: url(../img/approach/bg-faq.svg);
      background-repeat: no-repeat;
      position: absolute;
      top: 170px;
      right: 185px;
      width: 165px;
      height: 110px;
      @media (max-width: 768px) {
        display: none;
      }
    }
    p {
      text-align: center;
      margin-top: 20px;
      font-family: $main-font;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      span {
        font-weight: 600;
      }
    }

    h4 {
      width: 1250px;
      display: flex;
      justify-content: start;
      margin-top: 75px;
      @media (max-width: 768px) {
        max-width: 100%;
        margin-top: 35px;
      }
      &:before {
        content: "";
        background-image: url(../img/approach/souligne-213.svg);
        background-repeat: no-repeat;
        position: absolute;
        height: 10px;
        width: 220px;
        bottom: -5px;
        @media (max-width: 768px) {
          background-image: url(../img/approach/souligne-147.svg);
          width: 145px;
          bottom: -8px;
        }
      }
    }

    .block-faq {
      margin-top: 17px;
      width: 1250px;
      @media (max-width: 768px) {
        max-width: 100%;
        margin-top: 35px;
      }
      .faq-group {
        margin-bottom: 20px;
        border-bottom: 3px solid black;
        .faq-question {
          align-items: center;
          @media (max-width: 768px) {
            font-size: 18px;
            line-height: 22px;
          }
          &:after {
            position: relative;
            top: -5px;
            width: 20px;
            height: 10px;
            content: " ";
            background-image: url(../img/approach/arrow-bottom-black.svg);
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
            border-radius: unset;
            margin-left: unset;
            min-width: unset;
            background-color: unset;
            @media (max-width: 768px) {
              width: 40px;
              height: 20px;
            }
          }
          &.open {
            &:after {
              background-image: url(../img/approach/arrow-top-black.svg);
            }
          }
        }
        .faq-answer {
          font-family: $main-font;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: #000000;
          @media (max-width: 768px) {
            font-size: 16px;
            line-height: 18px;
          }
          &.open {
            padding-bottom: 35px;
          }
          span {
            font-weight: 600;
            display: block;
            margin-bottom: 5px;
          }
        }
      }
    }

    .socials {
      position: absolute;
      bottom: -32px;
      ul {
        display: flex;
        gap: 25px;
      }
    }
  }

  #newsletter {
    padding-top: 80px;
    padding-bottom: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    &:before {
      @media (min-width: 1250px) {
        content: "";
        background-image: url(../img/approach/bg-nl-left.svg);
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        height: 391px;
        width: 181px;
        bottom: 0;
        left: 0;
      }

    }
    &:after {
      @media (min-width: 1250px) {
        content: "";
        background-image: url(../img/approach/bg-nl-right.svg);
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        height: 375px;
        width: 476px;
        right: 0;
        bottom: -5px;
      }
      @media (max-width: 1249px) {
        content: "";
        background-image: url(../img/approach/bg-nl-mobile.svg);
        background-repeat: no-repeat;
        background-size: cover;
        width: 348px;
        height: 251px;
        bottom: 0;
        position: absolute;
      }
    }
    h3 {
      &:after {
        content: "";
        background-image: url(../img/approach/souligne-154.svg);
        background-repeat: no-repeat;
        position: absolute;
        height: 10px;
        width: 160px;
        bottom: -5px;
        right: 0px;
      }
    }

    p {
      color: $color-font-dark;
      text-align: center;
      font-family: $main-font;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 15px;
      @media (max-width: 768px) {
        text-wrap-style: balance;
      }
    }
    .form-newsletter {
      align-items: center;
      background-color: #ffffff;
      border-radius: 28px;
      display: flex;
      padding: 4px 4px 4px 24px;
      margin-top: 28px;
    }
    .form-newsletter {
      border-radius: 100px;
      border: 2px solid $color-font-dark;
      width: 735px;
      @media (max-width: 768px) {
        max-width: 100%;

      }
      input {
        border: none;
        color: $color-font-medium;
        flex-grow: 1;
        line-height: 24px;
        margin-left: 15px;
        outline: none;
      }


    }

    @media (min-width: 768px) {
      .confidentialite {
        text-align: center;
        text-wrap-style: balance;
      }
      .form-newsletter input {
        font-size: 16px;
        margin-left: 15px;
      }
    }

    img#bg-center {
      position: absolute;
      bottom: 0;
      @media (max-width: 1250px) {
        display: none;
      }
    }
  }
}

