.store-links {
    .item{
        display: inline-block;
        height: 60px;
        margin-right: 40px;
        transform: scale(0.8);
        transform-origin: left;
        @media (min-width: $screen-sm-min) {
            transform: none;
        }
    }
}