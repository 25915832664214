.block_hidden_only_for_screen {
  display: none;
}

.unvisible {
  display: none;
}

.loading-overlay {
  position: relative;
  pointer-events: none;
  cursor: not-allowed;
  -webkit-touch-callout: none;
  @include user-select(none);
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.5) url('../img/preloader.gif') no-repeat center center;
    z-index: 110;
  }
}

.no-gutter {
  &.row {
    margin-right: 0;
    margin-left: 0;
  }
  > [class*="col-"] {
    padding-right:0;
    padding-left:0;
  }
}

// Usage: <ul class="list-grid row">
//          <li class="col-md-3">...</li>
//
// Important: To ensure this work correctly on mobile,
// make sure there are no whitespaces between <li> tags:
// </li><li>
//
ul.list-grid {
  display: block;
  list-style: none;
  padding: 0;

  // Remove whitespace between inline elements (out columns)
  // May not work on mobile. Make sure there is no whitespace inside the templates
  // between </li><li>
  font-size: 0;

  > li {
    // Restore base font size
    font-size: $font-size-base;

    display: inline-block;
    vertical-align: top;
    float: none;
  }
}
