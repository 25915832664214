@use "text" as text;

.alert {
  font-weight: bold;
  padding: 20px 114px 20px 24px;
  border-radius: 24px;
  border: 0;
  display: flex;
  @include text.font-body-2;

  ul, ol {
    padding-left: 15px;
    margin-left: 27px;
    margin-bottom: 0;
    li {
      list-style-type: decimal;
      font-weight: normal;
    }
  }
  &.alert-success:before {
    font-family: $font-icon;
    margin-right: 24px;
    content: "\f058"; // fa-check-circle
  }
  &.alert-danger:before {
    font-family: $font-icon;
    margin-right: 24px;
    content: "\f057"; // fa-times-circle
  }
  &.alert-warning:before {
    font-family: $font-icon;
    margin-right: 24px;
    content: "\f06a"; // fa-exclamation-circle
  }
  &.alert-info:before {
    font-family: $font-icon;
    margin-right: 24px;
    content: "\f05a"; // fa-info-circle
  }

  .lnk{
      display: none;
  }
}
