.container-tabs {
    
    border: 0;
    border-bottom: 1px solid rgb($color-font-medium, 0.4);

    .col-xs-12 {
        display: flex;
        .tab {
            display: inline-flex;
            padding: 20px 28px;
            @include font-body-2;
            color: $color-font-dark;
            //font-weight:600;
            cursor: pointer;
            align-items: center;
            border-bottom: 2px solid transparent;
    
            img{
                max-height: 24px;
                margin-right: 12px;
            }
    
            &.active {
                @include font-button-2;
                color: $color-primary;
                border: 0;
                border-bottom: 2px solid $color-primary;
            }
        }
    }
}