
.slick-arrow {
    width: 64px;
    height: 64px;
    background-color: $color-background;
    padding: 20px;
    color: $color-secondary;
    border-radius: 64px;
    z-index: 100;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s ease;

    display: none !important;

    @media (min-width: $screen-sm-min) {
        display: block !important;  
    }

    &:hover {
        background-color: $color-secondary;
    }

    &:focus {
        background-color: $color-background;
        &:hover {
            background-color: $color-secondary;
        }
    }

    &.slick-next {
        margin-right: 30px;
        &::before {
            content: url('../img/slider/ic-next.svg');
        }
        
        &:hover{
            &::before {
                content: url('../img/slider/ic-next-white.svg');
            }
        }
    }

    &.slick-prev {
        margin-left: 30px;
        &::before {
            content: url('../img/slider/ic-back.svg');
        }
        &:hover{
            &::before {
                content: url('../img/slider/ic-back-white.svg');
            }
        }
    }

    &.slick-disabled {
        opacity: 0;
        cursor: default;
    }   
}

.card-carrousel{
    
    .slick-list{
        overflow: unset;
    }

    .slick-slide{
        margin-right: 30px;
    }
}

.carousel-full {
    
    position: relative;

    .slick-next {
        
        position: absolute;
        top: 88px;
        right: 170px;
        left: auto;
        bottom: auto;
        margin: 0;

        
    }
    .slick-prev {
        top: 88px;
        right: 254px;
        left: auto;
        bottom: auto;
        position: absolute;
        margin: 0;

    }

    .slick-dots {
        text-align: right;
        bottom: 24px;
        right: 15px; 
        
        @media (min-width: $screen-sm-min) {
            bottom: 88px;
            right: 150px;
        }
        li {
            width: 15px;
            button::before {
                font-size: 36px;
                color: $color-font-light;
            }
        }
    }
}
