/*!
 * Bootstrap v3.3.6 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "bootstrap/bootstrap/variables";
@import "bootstrap/bootstrap/mixins";

// Reset and dependencies
@import "bootstrap/bootstrap/normalize";
@import "bootstrap/bootstrap/print";
//@import "bootstrap/bootstrap/glyphicons";

// Core CSS
@import "bootstrap/bootstrap/scaffolding";
@import "bootstrap/bootstrap/type";
@import "bootstrap/bootstrap/code";
@import "bootstrap/bootstrap/grid";
@import "bootstrap/bootstrap/tables";
@import "bootstrap/bootstrap/forms";
@import "bootstrap/bootstrap/buttons";

// Components
@import "bootstrap/bootstrap/component-animations";
@import "bootstrap/bootstrap/dropdowns";
@import "bootstrap/bootstrap/button-groups";
@import "bootstrap/bootstrap/input-groups";
@import "bootstrap/bootstrap/navs";
@import "bootstrap/bootstrap/navbar";
@import "bootstrap/bootstrap/breadcrumbs";
@import "bootstrap/bootstrap/pagination";
@import "bootstrap/bootstrap/pager";
@import "bootstrap/bootstrap/labels";
@import "bootstrap/bootstrap/badges";
@import "bootstrap/bootstrap/jumbotron";
@import "bootstrap/bootstrap/thumbnails";
@import "bootstrap/bootstrap/alerts";
@import "bootstrap/bootstrap/progress-bars";
@import "bootstrap/bootstrap/media";
@import "bootstrap/bootstrap/list-group";
@import "bootstrap/bootstrap/panels";
@import "bootstrap/bootstrap/responsive-embed";
@import "bootstrap/bootstrap/wells";
@import "bootstrap/bootstrap/close";

// Components w/ JavaScript
@import "bootstrap/bootstrap/modals";
@import "bootstrap/bootstrap/tooltip";
@import "bootstrap/bootstrap/popovers";
// @import "bootstrap/bootstrap/carousel";

// Utility classes
@import "bootstrap/bootstrap/utilities";
@import "bootstrap/bootstrap/responsive-utilities";
