
  
  @use "../vendor/bootstrap/bootstrap/variables" as bs;
 
  #blockcart-header {
    height: 48px;
    padding: 12px 20px;;
    background: $color-primary;
    color: $color-font-light;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 24px;
    
    @include font-button-2();


    @media (max-width: bs.$screen-sm-min) {
        transform: scale(0.8);
        transform-origin: right center;
    }

    &:before{
        content: url('../img/cart/ic-cart.svg');
        
        margin-right: 10px;
        height: 20px;
    }

    .ajax_cart_total{
        margin-right: 5px;
    }


    /*&::after {
        content: "\f0d7";
        font-family: "FontAwesome";
        display: inline-block;
        margin-left: 10px;
    }

    &:hover:after {
        content: "\f0d8";
    }*/

  }

  #blockcart-dropdown {
    position: absolute;
    z-index: 4;
    background: #fff;
    width: 100%;
    border: 1px solid #ccc;
    border-top: none;
    padding: 10px;
    max-height: 75vh;
    overflow: auto;
  }
  
  #blockcart-dropdown dl {
    margin-bottom: 10px;
  }
  
  #blockcart-dropdown dt {
    font-weight: normal;
    margin-bottom: 10px;
    position: relative;
  }
  
  #blockcart-dropdown dt:last-of-type {
    margin-bottom: 0;
  }
  
  #blockcart-dropdown .product-attributes {
    padding-bottom: 5px;
  }
  
  #blockcart-dropdown .vouchers {
    margin: 0 0 10px;
  }
  
  #blockcart-dropdown .vouchers tr td {
    padding: 5px;
  }
  
  .cart_block_customizations {
    padding: 5px 0;
    margin-bottom: 10px;
    border: 1px dashed #333;
    border-left: none;
    border-right: none;
  }
  
  .cart_block_customizations > li {
    position: relative;
  }
  
  .deleteCustomizableProduct {
    float: right;
  }
  
  .cart_block_list .remove_link {
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .cart-images {
    float: left;
    margin-right: 5px;
  }
  
  .cart-info {
    padding-right: 10px;
  }
  
  .cart-info .product-name {
    padding-bottom: 5px;
  }
  
  .cart-prices {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .cart-prices-line {
    border-top: 1px solid #333;
    padding: 5px 0;
  }
  
  .cart-prices-line:last-of-type {
    padding-bottom: 0;
  }
  
  .cart_block_shipping_cost,
  .cart_block_tax_cost,
  .cart_block_total,
  .cart_block_wrapping_cost {
    float: right;
  }
  
  .layer_cart_overlay {
    background-color: #000;
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 98;
    opacity: 0.2;
    filter: alpha(opacity=20);
  }
  
  #layer_cart {
    background-color: #fff;
    position: absolute;
    display: none;
    z-index: 99;
    width: 84%;
    margin-right: 8%;
    margin-left: 8%;
  }
  
  #layer_cart .cross {
    font-size: 2em;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 2;
  }
  
  .layer_cart_product {
    overflow: hidden;
    position: static;
    padding: 30px;
  }
  
  .layer_cart_product .title {
    margin-top: 0;
  }
  
  .layer_cart_cart {
    background: #fafafa;
    border-left: 1px solid #ccc;
    padding: 30px 30px 170px 30px;
    position: relative;
  }
  
  @media (min-width: 1200px) {
    .layer_cart_cart {
      min-height: 318px;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
    .layer_cart_cart {
      min-height: 360px;
    }
  }
  
  @media (max-width: 991px) {
    .layer_cart_cart {
      border-left: none;
      border-top: 1px solid #ccc;
    }
  }
  
  .layer_cart_cart .title {
    margin-top: 0;
    padding-bottom: 0.35em;
    border-bottom: 1px solid #ccc;
  }
  
  .layer_cart_cart .button-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 30px 20px;
  }
  
  .layer_cart_cart .pager {
    margin: 0;
  }
  
  @media (max-width: 767px) {
    .layer_cart_cart .pager > li > a {
      float: none;
      display: block;
      margin-top: 10px;
    }
  }
  
  .crossseling-content {
    position: relative;
    padding: 30px;
    border-top: 1px solid #ccc;
  }
  
  .crossseling-content h3 {
    margin: 0 0 0.75em;
  }