
@use "text" as text;
@use "../vendor/bootstrap/bootstrap/variables" as bs;
@use "../theme_variables" as theme;

.chips {
    font-size: 16px;
    color: theme.$color-font-dark;
    padding: 12px 20px;
    border: 1px solid rgba(theme.$color-font-medium, 0.4);
    border-radius: 24px;
    display: inline-block;
    background-color: transparent;

    margin-right: 16px;

    @media (min-width: bs.$screen-md-min) {
        margin-right: 24px;
    }

    &.active {
        text-shadow: 0 0 .65px theme.$color-primary;
        background-color: theme.$color-primary;
        border: 1px solid theme.$color-primary;
        color: theme.$color-font-light;
    }

    &.chips-inverted {
        border: 1px solid theme.$color-font-light;
        color: theme.$color-font-light;
        background-color: transparent;

        &.active {
            text-shadow: 0 0 .65px theme.$color-font-light;
            border: 1px solid theme.$color-primary-beta;
            background-color: theme.$color-primary-beta; 
        }
    }

    &.chips-small{
        font-size: 14px;
        padding: 6px 12px;
        margin-right: 16px;
        &.active {
            font-weight: normal;
        }
    }
}
