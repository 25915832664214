.delivery-container {

    .slide {
        padding-top: 56px;
        padding-bottom: 56px;
        @media (min-width: $screen-sm-min) {
            padding-top: 96px;
            padding-bottom: 96px;
        }

        display: none;

        &.active{
            display: block;
        }

        h2, h3 {
            margin-top: 0;
        }

        .form-delivery {
            display: flex;
            border-radius: 28px;
            background-color: white;
            align-items: center;
            padding: 4px 4px 4px 24px;
        
            input {
                outline: none;
                border: none;
                color: $color-font-medium;
                line-height: 24px;
                flex-grow:1;
                margin-left: 15px;
                
                @media (min-width: $screen-sm-min) {
                    margin-left: 15px;
                    font-size: 16px;
                } 

                &:focus {
                    outline: none;
                }
            }
            button{
                flex-grow: 0;
            }
        }

        #go-private, #go-company{
            img{
                
                margin-right: 12px;
            }
        }


        .bag{
            margin-bottom: 32px;
        }

        @media (max-width: $screen-sm-min) {
            .btn-small {
                font-size: 13px;
                margin-right: 0;
            }
        }

        .btn:not(.btn-tertiary-arrow) {
            margin-right: 24px;
        } 
    }   
}