// Nouvelle version de la popup Marketing
@use "../theme_variables" as theme;

.front-popup-background-overlay{
    position: fixed;
    width:100vw;
    height: 100vh;
    left:0;
    right:0;
    bottom:0;
    top:0;
    opacity: 0.6;
    -webkit-backdrop-filter: blur(28px);
    backdrop-filter: blur(28px);
    background-color: theme.$color-font-dark;
    z-index:2000;
}

.front-popup-container{
    padding: 112px 24px 40px 24px;
    max-width: 382px;
    
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 2050;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 24px;
    background:  url('../img/popup/velo.svg') center center no-repeat, theme.$color-font-dark;

    .front-popup-close{
        position: absolute;
        right: 24px;
        top: 24px;
        width: 48px;
        height: 48px;
        border-radius: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: theme.$color-font-extra-dark;
        &:hover{
            svg {
                transform: scale(1.1);
                transform-origin: center center;
            }
        }
    }

    .code{
        margin-left: auto;
        margin-right: auto;
        width:278px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background:  url('../img/popup/bg-code.svg');
        font-family: 'Space Grotesk', sans-serif;
        font-weight: 500;
        color: #703500;
        text-shadow: 0 2px 0 #d58a01;
        font-size:28px;
        .copy{
            border-radius: 8px;
            border: dashed 1px #d58a01;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            margin-left: 12px;
            cursor:pointer;

            &:hover svg{
                transform: scale(1.05);
                transform-origin: center center;
            }
        }
    }

    .links{
        display: flex;
        gap: 24px;
        align-items: center;
        justify-content: center;
        a{
            display: block;
        }
    }

}