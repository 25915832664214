
@use "text" as text;


.price {
  white-space: nowrap;
  // &.product-price {}
}

.old-price {
  color: $product-price-old-color;
  text-decoration: line-through;
  // &.product-price {}
}

.special-price {
  color: $product-price-special-color;
}

.price-percent-reduction {
  background-color: $product-price-reduction-bg;
  color: $product-price-reduction-color;;
  padding: 0 0.25em;
  display: inline-block;
  // &.small {}
}

.product-label-container {
  position: absolute;
  top: 0;
  left: 0;
  @include clearfix();
  .product-label {
    float: left;
  }
}

.product-label {
  font-size: 0.9em;
  margin: 4px 0 0 4px;
  padding: 2px 5px;
  font-weight: bold;
  background-color: #ccc;
  color: #fff;
  @include box-shadow(0 1px 2px rgba(0,0,0,0.15));
  &:last-of-type {
    margin-right: 0;
  }
}

.product-label-new {
  background: #6ad4ff;
}

.product-label-sale {
  background: #f13340;
}

.product-label-discount {
  background-color: #f13340;
}

.product-label-online {
  background-color: orange;
}


.product-item{

    &.incart{
        .product-image {
            background-color: $color-primary;
            img.featured-image{
                opacity: 0.5;
            }
        }
    }
    
    .product-image {
        position:relative;
        border-radius: 24px;
        overflow:hidden;
        margin-bottom: 16px;
        padding: 0 !important;

        img.featured-image {
            position:relative;
            width:100%;
            height: 200px;
            object-fit: cover;
            object-position: center;
            z-index:0;
            //transform: scale(1.5);
            @media (min-width: $screen-md-min) {
                //transform: scale(1.3);
            }
            
            &.disabled {
                opacity: 0.66;
            }
        }
        .ajax_add_to_cart_button, .edit-button  {
            display:none;
            position:absolute;
            bottom: 12px;
            right: 12px;
            z-index:2;

            @media (min-width: $screen-md-min) {
                display: flex;
                
            }
        }

        .add_to_cart_button {
          display:none;
          position:absolute;
          bottom: 12px;
          right: 12px;
          z-index:2;

          @media (min-width: $screen-md-min) {
            display: flex;

          }
      }
    }

    .product-info {
        @media (min-width: $screen-md-min) {
            padding: 0 !important;
            
        }
    }

    .ajax_add_to_cart_button, .edit-button, .btn-add-to-cart {
        display: flex;
        align-items: center;

            @media (min-width: $screen-md-min) {
                display: none;
            }
        

        .ajax_cart_product_quantity {
            display:none;       
        }

        &.incart {
            width: auto !important;
            padding: 0 12px 0 16px;
            border-radius: 24px;

            &>span {
                display: flex;
            }
           
            .ajax_cart_product_quantity {
                
                    display:block;
                    margin-right: 12px;
                    @include text.font-subtitle-2();
            }
        }
    }

    .availability {

        @media (min-width: $screen-md-min) {
            position:absolute;
            top:12px;
            left: 12px;
            z-index:2;
        }
        
        .label {
            padding: 8px 12px;
            border-radius: 20px;
            background-color: $color-secondary;
            @include text.font-caption();
        }
    }

    .content_price {
        margin-bottom: 5px;
    }

    h2 {
        margin-top: 0;
    }


    .debug {
        display: none;
    }
    
}