$header-banner-bg: #000;
$page-body-bg: #fff;
$page-content-bg: #fff;

body {
  background-color: $page-body-bg;
  &.content_only {
    background-color: $page-content-bg;
  }
}

#header-blocks {
  background-color: $page-content-bg;
  padding-top: 15px;
  position: relative;
}

#columns {
  position: relative;
  padding-bottom: 50px;
  padding-top: 15px;
  background-color: $page-content-bg;
}

#header-banners {
  background-color: $header-banner-bg;
}

#header {
  .navbar {
    margin-bottom: 0;
  }
}

.you-know {
  background: #EEF4F1;
  border-radius: 20px;
  padding: 20px;
  h2 {
    font-size: 16px;
    margin-top: 0;
  }
  p {
    font-size: 16px;
  }
  .col-xs-1 {
    /*margin-top: 24px;*/
    svg {
      vertical-align: -webkit-baseline-middle;
    }
  }
  max-width: 600px;
}

#loginModal {
  background-color: #6D7175;
  .modal-header {
    border-bottom : unset;
    padding: unset;

    .close {
      opacity: 1;
    }
  }
  .modal-body {
    padding: unset;
    margin-top: 48px;
  }
  .modal-content {
    width: 460px;
    height: 610px;
    display: flex;
    flex-direction: column;
    padding: 40px;

    .modal-body {
      .form-group .show-password-secondary::before {
        content: url("../img/form/ic-eye-open.svg")
      }
    }
  }
  .modal-dialog {
    width: 460px;
  }

}