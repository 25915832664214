//
// Common Order Page Styles
//

input.form-control.discount_name{
    padding:0;
}

.not-displayable {
  display: none;
}

#order_step {
  margin-bottom: 15px;
  .completed {
    background: #eee;
  }
}

//
// Step 1: Cart Summary
//

//
// Step 3: Address
//

#id_address_invoice,
#id_address_delivery {
  width: 270px;
}

.address_delivery,
#address_invoice_form {
  position: relative;
}

.addresses {
  .waitimage {
    display: none;
    width: 24px;
    height: 24px;
    background-image: url("../../../img/loader.gif");
    background-repeat: no-repeat;
    position: absolute;
    top: 31px;
    left: 280px;
  }
}

.multishipping_close_container {
  text-align: center;
}

//
// Step 4: Carriers
//

.delivery_option {
  table {
    background: #fff;
    > tbody > tr > td {
      vertical-align: middle;
    }
  }
}

.delivery_option_radio {
  width: 20px;
}

.delivery_option_logo {
  width: 90px;
  text-align: center;
}

//.delivery_option_price { width: 160px; }

.carrier_title {
  font-weight: bold;
}

#gift_div {
  display: none;
}

//
// Step 5: Payment
//

.payment_module {
  a {
    display: block;
    border: 1px solid $table-border-color;
    font-size: 1.25em;
    font-weight: bold;
    padding: 33px 40px 34px 99px;
    position: relative;
    &:hover {
      background-color: #f6f6f6;
    }
    &.bankwire {
      background: url('../img/bankwire.png') 15px 12px no-repeat #fbfbfb;
    }
    &.cheque {
      background: url('../img/cheque.png') 15px 15px no-repeat #fbfbfb;
    }
    &.cash {
      background: url('../img/cash.png') 15px 15px no-repeat #fbfbfb;
    }
    &.cheque,
    &.bankwire,
    &.cash {
      &:after {
        display: block;
        content: "\f054";
        position: absolute;
        right: 15px;
        margin-top: -11px;
        top: 50%;
        font-family: $font-icon;
        font-size: 25px;
        height: 22px;
        width: 14px;
      }
    }
  }
}

//
// Order: Advanced
//

.payment_option_form {
  display: none;
}

.payment_option_logo {
  height: 50px;
  max-height: 50px;
}

.payment_module_adv {
  height: 120px;
  max-height: 120px;
}

.payment_option_selected {
  i {
    color: green;
  }
  display: none;
  margin-top: 15px;
}