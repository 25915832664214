.page-heading {
  padding: 10px;
  background-color: #f5f5f5;
  margin: 0 0 20px;
  border-bottom: 1px solid $ps-border-color;

  // &.product-listing {}
  // .heading-counter {}

  @include clearfix(); // .heading-counter is using float-right;
                       // This fixes element height when the is overflow

  span.lighter {
    color: $text-muted;
  }
}

.page-subheading {
  font-size: $font-size-h3;
  padding: 0 0 8px;
  margin: 0 0 10px;
  border-bottom: 1px solid $ps-border-color;
}

.page-product-heading {
  padding: 10px;
  margin: 0 0 15px;
  border-bottom: 1px solid $ps-border-color;
  background: #f5f5f5;
}
