@use "text" as text;

$has-error-bg  : $state-danger-bg;
$has-warning-bg: $state-warning-bg;
$has-success-bg: $state-success-bg;

textarea {
  resize: vertical;
}

label {
  &.required {
    &:after {
      content: '*';
      color: $color-secondary;
    }
  }
}

input:focus, input:valid,  textarea:focus{
   color: $color-font-dark !important;
}

/*
.form-error {
  @extend .has-error;
  .form-control {
   background-color: $has-error-bg;
  }
}

.form-ok {
  @extend .has-success;
  .form-control {
    background-color: $has-success-bg;
  }
}

.form-warning {
  @extend .has-warning;
  .form-control {
    background-color: $has-warning-bg;
  }
}*/


.input-group.plus-minus{
    display:inline-flex;
    border: 1px solid rgba($color-font-medium, 0.4);
    border-radius: 32px;
    height: 64px;
    padding: 20px;
    justify-content: space-between;
    align-items: center;

    &.plus-minus-small{
        height: 48px;
        padding: 12px;
    }

    input{
        @include text.font-button-2();
        color: $color-font-dark;
        width: 40px;
        padding:0;
        outline: none;
        border:none;
        text-shadow: none;
        box-shadow: none;
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}

.input-group.btn-right {
    width: 56px;
    padding: 4px 4px 4px 24px;
    border: 1px solid rgba($color-font-medium, 0.4);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 28px;

    input{
        @include text.font-body-2;
        color: $color-font-medium;
        outline: none;
        box-shadow: none;
        border:none;
        width: 100%;
        margin-right: 16px;
    }

    button{
        flex-shrink: 0;
    }

    .btn-addon{
        flex-shrink: 0;
        flex-grow: 0;
    }
}

/*https://codepen.io/foxeisen/pen/mMRmdx*/
//position absolute mixin

@mixin position-absolute ($top: null, $left: null, $right: null, $bottom: null) {
    position: absolute;
    top: $top;
    left: $left;
    right: $right;
    bottom: $bottom;
  }
  
  //transitions mixin
  @mixin transition-mix($property: all, $duration: 0.2s, $timing: linear, $delay: 0s) {
    transition-property: $property;
    transition-duration: $duration;
    transition-timing-function: $timing;
    transition-delay: $delay;
  }

.checkbox-group {
    display: flex;
    justify-content: space-between;
  
    input[type=checkbox]:not(:checked),
    input[type=checkbox]:checked{
    position: absolute;
    left: -9999px;
    }
  
  label {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
  }
  
  /* checkbox effect #1 */
  
  input[type=checkbox]:not(:checked) + label:before,
  input[type=checkbox]:checked + label:before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 2px solid $color-tertiary-beta;
      box-sizing: border-box;
      border-radius: 3px;
      margin-right: 20px;
  }
  
  input[type=checkbox]:not(:checked) + label:after,
  input[type=checkbox]:checked + label:after {
    content: "\f00c";
    font: {
      family: fontawesome;
      size: 11px;
    }
    display: flex;
    height: 100%;
    align-items:center;
    @include position-absolute($left: 2px, $top: 0);
    color: $color-font-light;
    transform-origin: center center;
  }
  
  input[type=checkbox]:not(:checked) + label:after {
    transform: scale(0);
  }
  
  input[type=checkbox]:checked + label:before {
    background-color: $color-tertiary;
    border-color: $color-tertiary;
    @include transition-mix;
  }
  
  input[type=checkbox]:checked + label:after {
    transform: scale(1);
    @include transition-mix($delay: 0s);
  }
}


.radio-group {
    display: flex;
    justify-content: space-between;
  
    input[type=radio]:not(:checked),
    input[type=radio]:checked{
    position: absolute;
    left: -9999px;
    }
  
  label {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
  }
  
  /* checkbox effect #1 */
  
  input[type=radio]:not(:checked) + label:before,
  input[type=radio]:checked + label:before {
      content: "";
      display: inline-block;
      width: 19px;
      height: 19px;
      border: 2px solid $color-font-medium;
      box-sizing: border-box;
      border-radius: 50%;
      margin-right: 20px;
      flex-shrink:0;
  }
  
  input[type=radio]:not(:checked) + label:after,
  input[type=radio]:checked + label:after {
    content: "\f00c";
    font: {
      family: fontawesome;
      size: 11px;
    }
    display: flex;
    height: 100%;
    align-items:center;
    @include position-absolute($left: 4px, $top: 0);
    color: $color-font-light;
    transform-origin: center center;
    flex-shrink:0;
  }
  
  input[type=radio]:not(:checked) + label:after {
    transform: scale(0);
  }
  
  input[type=radio]:checked + label:before {
    background-color: $color-tertiary;
    border-color: $color-tertiary;
    @include transition-mix;
  }
  
  input[type=radio]:checked + label:after {
    transform: scale(1);
    @include transition-mix($delay: 0s);
  }
}

.form-group{

    position: relative;
    margin-bottom: 24px;
    
    input {
        display: inline-block;
        width: 100%;
        height: 56px;
        @include text.font-body-2();
        padding: 16px 16px 16px 24px;
        border-radius: 28px;
        border: solid 1px rgba(109, 113, 117, 0.4);
        background-color: transparent;
        box-shadow: none !important;
        outline: none !important;
        transition: all 0.2s ease;
        color: $color-font-medium;

        &::placeholder{
            color: $color-font-medium;
        }

        &:active, &:focus {
            border-color: $color-primary;
        }

        &:active+label, &:focus+label , &.active+label {
            @include text.font-caption();
            color: $color-primary;
            background-color: white;
            padding: 4px;
            left: 24px;
            top: -13px;
        }

        &.active {
            border-color: rgba($color-font-dark, 0.5);
            color: $color-font-dark;

            &+label{
                color: $color-font-dark;
            }
            &:focus+label{
                color: $color-primary;
            }
        }

        &.form-icon-trailing{
            padding-right: 60px;
        }

        &.form-icon-leading{
            padding-left: 60px;
        }

        &[disabled]{
            color: rgba($color-font-medium, 0.4);
        }
    }

    .trailing {
        position: absolute;
        right: 20px;
        top: 16px;

        &.arrow{
            right: 25px;
            top: 18px;
        }
    }

    .leading{
        position: absolute;
        left: 20px;
        top: 16px;
    }


    label {
        @include text.font-body-2();
        position: absolute;
        top: 16px;
        left: 24px;
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        pointer-events: none;
        transform-origin: 0 0;
        transition: all .2s ease-out;
        color: rgba(0,0,0,.6);
        z-index:2;
    }

    i.show-password{
        &::before{
            content: url('../img/form/ic-eye-open.svg');
            cursor:pointer;
        }

        &.show::before{
            content: url('../img/form/ic-eye-closed.svg');
        }
    }

    .show-password-secondary{
        &::before{
            content: url('../img/form/ic-eye-open-secondary.svg');
            cursor:pointer;
        }

        &.show::before{
            content: url('../img/form/ic-eye-closed.svg');
        }
    }
}

.help-block{
    margin-left: 24px;
}

.bg-background {
    input:active+label ,  input:focus+label , input.active+label {
        background-color: $color-background;
    }
}

.bg-background-beta {
    input:active+label , input:focus+label , input.active+label {
        background-color: $color-background-beta;
    }
}




/*======================================
  select-address v1.13.0
======================================*/
/* Items box */
.select-address-wrapper {
	position: relative;
	cursor: pointer;
}

.select-address-responsive {
	width: 100%;
}

.select-address {
    padding: 16px;
    border-radius: 28px;
    border: solid 1px rgba($color-font-medium, 0.4);
	position: relative;
	overflow: hidden;

    &.active{
        border-color: rgba($color-font-dark, 0.5);
        color: $color-font-dark;

        .button{
            color: $color-font-dark;
        }

        .label{
            color: $color-font-dark;
        }
    }

	.label {
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
        -webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
        padding: 0;
        text-align: left;
		margin: 0 38px 0 10px;
		@include text.font-body-2();
		color: $color-font-medium;
	}

	.button {
		display: block;
		position: absolute;
        right: 9px;
        top: 9px;
		width: 38px;
		height: 38px;
		line-height: 38px;
		color: $color-font-medium;
		text-align: center;
        transition: transform 0.3s ease;
        transform-origin: center;
        font-size: 16px;

		&:after {
			content: " ";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			width: 0;
			height: 0;
			border: 4px solid transparent;
			//border-top-color: $border-top-color_1;
			border-bottom: none;
		}
	}
}

/*
.select-address-focus {
	.select-address {
		border-color: $border-color_1;
	}
}
.select-address-hover {
	.select-address {
		border-color: $border-color_2;
		.button {
			color: $color_3;
			&:after {
				border-top-color: $border-top-color_2;
			}
		}
	}
}*/

.select-address-open {
	z-index: 9999;

	.select-address {
		//border-color: $border-color_2;
	}

	.select-address-items {
		display: block;
	}

    .button{
        transform: rotate(180deg);
    }
}

.select-address-disabled {
	filter: alpha(opacity=50);
	opacity: 0.5;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.select-address-hide-select {
	position: relative;
	overflow: hidden;
	width: 0;
	height: 0;

	select {
		position: absolute;
		left: -100%;
	}
}

.select-address-hide-select.select-address-is-native {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 10;

	select {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 100%;
		width: 100%;
		border: none;
		z-index: 1;
		box-sizing: border-box;
		opacity: 0;
	}
}

.select-address-input {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	outline: none !important;
	border: none !important;
	*font: 0/0 a !important;
	background: none !important;
}

.select-address-temp-show {
	position: absolute !important;
	visibility: hidden !important;
	display: block !important;
}

.select-address-items {
	display: none;
	position: absolute;
	top: calc(100% + 10px);
	left: 0;
	z-index: -1;
	box-shadow: 0 0 10px -6px;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
    background-color: $color-font-light;

	.select-address-scroll {
		height: 100%;
		overflow: auto;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		@include text.font-body-2();
		min-height: 20px;
	}

	li {
		list-style: none;
		padding: 0;
		margin: 0;
        @include text.font-body-2();
		min-height: 20px;
		display: block;
		padding: 10px;
		color: $color-font-dark;
		cursor: pointer;

		&:hover {
			//font-weight:600;
		}

        &[data-index="0"]{
            display: none;
        }

        &.selected {
            color: $color-primary;
        }

        /*
        &.highlighted {
            background: #D0D0D0;
            color: $color_1;
        }*/

        .update{
           float: right;
        }

        &.last {
            color: $color-primary;
            @include text.font-button-2();
            &::before{
                content: url('../img/booking/ic-add.svg'); 
                width: 24px;
                height: 24px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 12px;
            }
        }

        &:first-of-type .update, &:last-of-type .update{
            display:none;
        }
	}
	
	.disabled {
		filter: alpha(opacity=50);
		opacity: 0.5;
		cursor: default !important;
		background: none !important;
		//color: $color_4 !important;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.select-address-group {
		.select-address-group-label {
			font-weight: bold;
			padding-left: 10px;
			cursor: default;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			background: none;
			//color: $color_1;
		}
		li {
			padding-left: 25px;
		}
	}
	.select-address-group.disabled {
		li {
			filter: alpha(opacity=100);
			opacity: 1;
		}
	}
}
.select-address-above {
	.select-address-items {
		top: auto;
		bottom: 100%;
	}
}



/*======================================
  select-slot v1.13.0
======================================*/
/* Items box */
.select-slot-wrapper {
	position: relative;
	cursor: pointer;
}

.select-slot-responsive {
	width: 100%;
}

.select-slot {

    padding: 12px;
    border-radius: 28px;
    border: solid 1px #dbdbdb;
    color: $color-font-dark;
	position: relative;
	overflow: hidden;
    

	.label {
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
        -webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
        padding: 0;
        text-align: center;
		margin: 0 10px 0 10px;
		@include text.font-body-2();
		color: $color-font-dark;
	}

	.button {
		display: none;
		position: absolute;
        right: 6px;
        top: 5px;
		width: 38px;
		height: 38px;
		line-height: 38px;
		color: $color-font-dark;
        font-size: 16px;
		text-align: center;
        transition: transform 0.3s ease;
        transform-origin: center;

		&:after {
			content: " ";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			width: 0;
			height: 0;
			border: 4px solid transparent;
			//border-top-color: $border-top-color_1;
			border-bottom: none;
		}
	}

    &.active {
        width: 150px;
        
        background-color: $color-primary;
        border-color: $color-primary;

        .label{
            color: $color-font-light;
            margin: 0;
        }

        .button {
            color: $color-font-light;
            display: none;
        }
    }
}


.select-slot-open {
	z-index: 9999;
    width: 150px !important;

	.select-slot {
		background-color: $color-primary;
        border-color: $color-primary;

        .label{
            color: $color-font-light;
            margin: 0 26px 0 26px;
        }

        .button {
            color: $color-font-light;
            display: block;
        }
	}

	.select-slot-items {
		display: block;
        width: 150px !important;
	}

    .button{
        transform: rotate(180deg);
    }
}

.select-slot-disabled {
	filter: alpha(opacity=50);
	opacity: 0.5;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.select-slot-hide-select {
	position: relative;
	overflow: hidden;
	width: 0;
	height: 0;

	select {
		position: absolute;
		left: -100%;
	}
}

.select-slot-hide-select.select-slot-is-native {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 10;

	select {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 100%;
		width: 100%;
		border: none;
		z-index: 1;
		box-sizing: border-box;
		opacity: 0;
	}
}

.select-slot-input {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	outline: none !important;
	border: none !important;
	*font: 0/0 a !important;
	background: none !important;
}

.select-slot-temp-show {
	position: absolute !important;
	visibility: hidden !important;
	display: block !important;
}

.select-slot-items {
	display: none;
	position: absolute;
	top: calc(100% + 10px);
	left: 0;
	z-index: -1;
	box-shadow: 0 0 10px -6px;
    padding: 15px;
    padding-right: 10px;
    border-radius: 16px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
    background-color: $color-font-light;

	.select-slot-scroll {
		height: 100%;
		overflow: auto;
    
        scrollbar-width: auto;
        scrollbar-color: rgb(99 186 158 / 20%) $color-font-light;
        

        /* Chrome, Edge, and Safari */
        &::-webkit-scrollbar {
            width: 11px;
        }

        &::-webkit-scrollbar-track {
            background: $color-font-light;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgb(99 186 158 / 20%);
            border-radius: 10px;
            border: 3px solid $color-font-light;
        }
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		min-height: 20px;
	}

	li {
		list-style: none;
		padding: 0;
		margin: 0;
        font-size: 14px;
        line-height: 16px;
		min-height: 20px;
		display: block;
		padding: 10px 0;
		color: $color-font-dark;
		cursor: pointer;


        &[data-index="0"]{
            /*display: none;*/
        }

		&:hover {
			//font-weight:600;
		}

        &.selected {
            color: $color-primary;
        }
	}
	
	.disabled {
		filter: alpha(opacity=50);
		opacity: 0.5;
		cursor: default !important;
		background: none !important;
		//color: $color_4 !important;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.select-slot-group {
		.select-slot-group-label {
			font-weight: bold;
			padding-left: 10px;
			cursor: default;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			background: none;
			//color: $color_1;
		}
		li {
			padding-left: 25px;
		}
	}
	.select-slot-group.disabled {
		li {
			filter: alpha(opacity=100);
			opacity: 1;
		}
	}
}
.select-slot-above {
	.select-slot-items {
		top: auto;
		bottom: calc(100% + 10px);
	}
}


/**
* Datepicker
*/


.ui-datepicker {
	width: 328px;
	padding: 12px;
    padding-bottom: 5px;
	display: none;
    background-color: $color-font-light;
    border-radius: 16px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
    margin-top: 10px;

	.ui-datepicker-header {
		position: relative;
		padding: .2em 0;
	}

	.ui-datepicker-prev {
		position: absolute;
		top: 2px;
		width: 1.8em;
		height: 1.8em;
		right: 50px;
        cursor: pointer;

		span {
			display: none;
		}
        &::after{
            width: 24px;
            height: 24px;
            content: url('../img/booking/ic-chevron-left.svg'); 
        }

	}
	.ui-datepicker-next {

		position: absolute;
		top: 2px;
		width: 1.8em;
		height: 1.8em;
		right: 10px;
        cursor: pointer;

		span {
			display: none;
		}

        &::after{
            width: 24px;
            height: 24px;
            content: url('../img/booking/ic-chevron-right.svg'); 
        }

	}

	.ui-datepicker-title {
		margin-left: 12px;
        margin-bottom: 12px;
		text-align: left;
        @include text.font-caption();
        color: $color-font-medium;

		select {
			font-size: 1em;
			margin: 1px 0;
		}

	}

	select.ui-datepicker-month-year {
		width: 100%;
	}

	select.ui-datepicker-month {
		width: 49%;
	}

	select.ui-datepicker-year {
		width: 49%;
	}

	table {
		width: 100%;
		font-size: .9em;
		border-collapse: collapse;
		margin: 0 0 .4em;
	}

	th {
		padding: .7em .3em;
		text-align: center;
		@include text.font-caption();
        color: $color-font-medium;
        font-weight: 400;
        order: 0;
        text-transform: uppercase;
	}

	td {
		border: 0;
		padding: 1px;
        font-size:14px;
        line-height: 22px;
        font-weight: 300;
        //font-family: $alt-font;

        &.ui-state-disabled{
            span {
                opacity: 0.45;
            }            
        }

		span {
			display: block;
			padding: 8px;
			text-align: center;
			text-decoration: none;
            color: $color-font-dark !important;
            border: 1px solid transparent;
		}

		a {
			display: block;
			padding: 8px;
			text-align: center;
			text-decoration: none;
            color: $color-font-dark !important;
            border-radius: 50%;
            border: 1px solid transparent;

            &:not(.ui-state-disabled):not(.ui-state-active):hover{
                border-color: $color-tertiary;
            }

            &.ui-state-active{
                border-color: $color-primary;
                background-color: $color-primary;
                color: $color-font-light !important;
            }
		}

       

	}

	.ui-datepicker-buttonpane {
		background-image: none;
		margin: .7em 0 0;
		padding: 0 .2em;
		border-left: 0;
		border-right: 0;
		border-bottom: 0;
        
		button {
			float: right;
			margin: .5em .2em .4em;
			cursor: pointer;
			padding: .2em .6em .3em;
			width: auto;
			overflow: visible;
		}

		button.ui-datepicker-current {
			float: left;
		}

	}
}

.ui-datepicker.ui-datepicker-multi {
	width: auto;
}

.ui-datepicker-multi {

	.ui-datepicker-group {
		float: left;

		table {
			width: 95%;
			margin: 0 auto .4em;
		}

	}

	.ui-datepicker-group-last {

		.ui-datepicker-header {
			border-left-width: 0;
		}

	}

	.ui-datepicker-group-middle {

		.ui-datepicker-header {
			border-left-width: 0;
		}

	}

	.ui-datepicker-buttonpane {
		clear: left;
	}

}

.ui-datepicker-multi-2 {

	.ui-datepicker-group {
		width: 50%;
	}

}

.ui-datepicker-multi-3 {

	.ui-datepicker-group {
		width: 33.3%;
	}

}

.ui-datepicker-multi-4 {

	.ui-datepicker-group {
		width: 25%;
	}

}

.ui-datepicker-row-break {
	clear: both;
	width: 100%;
	font-size: 0;
}





/*======================================
  select-selectricable v1.13.0
======================================*/
/* Items box */
.select-selectricable-wrapper {
	position: relative;
	cursor: pointer;
}

.select-selectricable-responsive {
	width: 100%;
}

.select-selectricable {
    padding: 16px;
    border-radius: 28px;
    border: solid 1px rgba(22, 49, 75, 0.5);
	position: relative;
	overflow: hidden;

	.label {
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
        -webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
        padding: 0;
        text-align: left;
		margin: 0 38px 0 10px;
		@include text.font-body-2();
		color: $color-font-dark;
	}

	.button {
		display: block;
		position: absolute;
        right: 9px;
        top: 9px;
		width: 38px;
		height: 38px;
		line-height: 38px;
		color: $color-font-medium;
		text-align: center;
        transition: transform 0.3s ease;
        transform-origin: center;

		&:after {
			content: " ";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			width: 0;
			height: 0;
			border: 4px solid transparent;
			//border-top-color: $border-top-color_1;
			border-bottom: none;
		}
	}
}

.select-selectricable-open {
	z-index: 9999;

	.select-selectricable-items {
		display: block;
	}

    .button{
        transform: rotate(180deg);
    }
}

.select-selectricable-disabled {
	filter: alpha(opacity=50);
	opacity: 0.5;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.select-selectricable-hide-select {
	position: relative;
	overflow: hidden;
	width: 0;
	height: 0;

	select {
		position: absolute;
		left: -100%;
	}
}

.select-selectricable-hide-select.select-selectricable-is-native {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 10;

	select {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 100%;
		width: 100%;
		border: none;
		z-index: 1;
		box-sizing: border-box;
		opacity: 0;
	}
}

.select-selectricable-input {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	outline: none !important;
	border: none !important;
	*font: 0/0 a !important;
	background: none !important;
}

.select-selectricable-temp-show {
	position: absolute !important;
	visibility: hidden !important;
	display: block !important;
}

.select-selectricable-items {
	display: none;
	position: absolute;
	top: calc(100% + 10px);
	left: 0;
	z-index: -1;
	box-shadow: 0 0 10px -6px;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
    background-color: $color-font-light;

	.select-selectricable-scroll {
		height: 100%;
		overflow: auto;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		@include text.font-body-2();
		min-height: 20px;
	}

	li {
		list-style: none;
		padding: 0;
		margin: 0;
        @include text.font-body-2();
		min-height: 20px;
		display: block;
		padding: 10px;
		color: $color-font-dark;
		cursor: pointer;

		&:hover {
			//font-weight:600;
		}

        &.selected {
            color: $color-primary;
        }
	}
	
	.disabled {
		filter: alpha(opacity=50);
		opacity: 0.5;
		cursor: default !important;
		background: none !important;
		//color: $color_4 !important;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.select-selectricable-group {
		.select-selectricable-group-label {
			font-weight: bold;
			padding-left: 10px;
			cursor: default;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			background: none;
			//color: $color_1;
		}
		li {
			padding-left: 25px;
		}
	}
	.select-selectricable-group.disabled {
		li {
			filter: alpha(opacity=100);
			opacity: 1;
		}
	}
}
.select-selectricable-above {
	.select-selectricable-items {
		top: auto;
		bottom: 100%;
	}
}