.slidable {
    
    overflow-x: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
    -webkit-overflow-scrolling: touch;

    &.inside{
        padding-left: 15px;
        padding-right: 15px;
        margin-left: -15px;
        margin-right: -15px;

    }

    &.draggable{
        cursor:grab;
        &:active{
            cursor:grabbing;
        }
    }

    .container{
        .row:not(.no-slide) {
            overflow-x: visible;
            white-space: nowrap;

            .item{
                display:inline-block;
            }
        }
    }
}