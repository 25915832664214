.popin-link {
    margin-top: 20px;
    background: #444;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 1rem;
      text-transform: uppercase;
  }
  .popin-link:hover {
      background: #888;
  }
  .popin {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    overflow: auto;
    padding: 20px;
    display: flex;
    z-index: 10000;
  }
  .popin-inner {
    position: relative;
    background: white;
    padding: 40px;
    border-radius: 24px;
    width: 80%;
    max-width: 480px;
    margin: auto;
    flex: none;
    text-align: left;
  }
  .popin-close {
    position: absolute;
    font-size: 0;
      text-decoration: none;
      top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .popin-close::before {
    content: "×";
    display: block;
    color: #000;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
  }

  .popin-button{
    a+a{
        margin-left: 24px;
    }
  }