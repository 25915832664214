@import "../../vendor/bootstrap/bootstrap/variables";

.newsletter-row {

        padding-top: 40px;
        padding-bottom: 40px;

        @media (min-width: $screen-sm-min) {
            padding-top: 96px;
            padding-bottom: 96px;
        }

    .newsletter-block {

        border-radius: 24px;
        padding: 24px;
        
        @media (min-width: $screen-sm-min) {
            padding: 40px;  
        }

        h2{
            margin-top: 0;
        }

        .form-newsletter {
            display: flex;
            border-radius: 28px;
            background-color: white;
            align-items: center;
            padding: 4px 4px 4px 24px;
        
            input {
                outline: none;
                border: none;
                color: $color-font-medium;
                line-height: 24px;
                flex-grow:1;
                margin-left: 15px;
                
                @media (min-width: $screen-sm-min) {
                    margin-left: 15px;
                    font-size: 16px;
                } 

                &:focus {
                    outline: none;
                }
            }
            button{
                flex-grow: 0;
            }
        }

        .step-2{
            display: none;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 40px 0;
            &.active{
                display: flex;
            }
        } 
    }  
}