
.triptych {

    .item-1, .item-2, .item-3 {
        overflow:hidden;
        border-radius: 22px;
        background-position: center;
        background-size: cover;

        box-shadow: 0 12px 33px 0 rgba(89, 43, 36, 0.2);
        @media (min-width: $screen-sm-min) {
            box-shadow: 30px 30px 50px 0 rgba(89, 43, 36, 0.2);
        }
        > svg {
            position: absolute;
        }
    }

    &.triptych-1 {

        .item-1 {

            background-image: url('../img/home/img-entreprise-3@2x.jpg');

            height: 277px;
            background-position: bottom;
            margin-bottom: 40px;

            @media (min-width: $screen-sm-min) {
                height: 342px;
                margin-top: 200px;
                margin-bottom: auto;
            }
            > svg {
                top: 175px;
                right: 100px;
                @media (max-width: $screen-sm) {
                    top: 52px;
                    right: 55px;
                }
            }
        }
    
        .item-2 { 
            height: 342px;
            background-image: url('../img/home/img-entreprise-1@2x.jpg');

            margin-bottom:40px;
            > svg {
                top: -20px;
                right: 105px;
                @media (max-width: $screen-sm) {
                    top: -21px;
                    right: 60px;
                }
            }
        }
    
        .item-3 {
            height: 211px;
            background-image: url('../img/home/img-entreprise-2@2x.jpg');
            > svg {
                top: 359px;
                right: 75px;
                @media (max-width: $screen-sm) {
                    top: 195px;
                    right: 45px;
                }
            }
        }
    }
    
    &.triptych-2 {
    
        .item-1 {
            height: 298px;
            background-image: url('../img/home/img-pdj-frais-3@2x.jpg');
            margin-top: 200px;
        }
    
        .item-2 { 
            height: 277px;
            background-image: url('../img/home/img-pdj-frais-1@2x.jpg');
            margin-bottom:30px;

            @media (min-width: $screen-sm-min) {
                height: 230px;    
            }
        }
    
        .item-3 {
            height: 342px;
            background-image: url('../img/home/img-pdj-frais-2@2x.jpg');
        }
    }

    &.triptych-3 {
    
        .item-1 {
            height: 284px;
            background-image: url('../img/signup/img-inscription-3@2x.jpg');
            margin-top: 200px;

            > svg {
                top: 175px;
                left: 95px;
                @media (max-width: $screen-sm) {
                    top: 52px;
                    right: 55px;
                }
            }
        }
    
        .item-2 { 
            height: 277px;
            //background-image: url('../img/signup/img-inscription-1@2x.jpg');
            background-image: url('../img/home/img-entreprise-1@2x.jpg');
            margin-bottom:30px;

            @media (min-width: $screen-sm-min) {
                height: 342px;    
            }
            > svg {
                top: -30px;
                left: 98px;
                @media (max-width: $screen-sm) {
                    top: -21px;
                    left: 60px;
                }
            }
        }
    
        .item-3 {
            height: 208px;
            //background-image: url('../img/signup/img-inscription-2@2x.jpg');
            background-image: url(../img/home/img-entreprise-2@2x.jpg);

            > svg {
                top: 348px;
                left: 78px;
                @media (max-width: $screen-sm) {
                    top: 195px;
                    right: 45px;
                }
            }
        }
    }

    &.triptych-4 {
    
        .item-1 {
            height: 279px;
            background-image: url('../img/booking/img-boutique-1@2x.jpeg');
        }
    
        .item-2 {     
            height: 196px;
            background-image: url('../img/booking/img-boutique-3@2x.jpeg');
        }
    
        .item-3 {
            height: 328px;
            background-image: url('../img/booking/img-boutique-2@2x.jpeg');
        }
    }

}


.triptych-horizontal{
    .row:first-of-type{
        margin-bottom: 30px;
    }
}

