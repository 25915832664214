.testimonial-container {
    position:relative;

    &::before {
        content:  url('../img/home/img-cafe.svg');
        position: absolute;
        z-index: 1;
        left: -160px;
        bottom: -163px;
       

        @media (min-width: $screen-sm-min) {
            left: auto;
            bottom: -191px;
            right: 30px;
        }
    }

    .testimonial-row {
        position:relative;
    
        padding-top: 56px;
        padding-bottom: 196px;
    
        @media (min-width: $screen-sm-min) {
            padding-top: 96px;
            padding-bottom: 96px;
        }
    
        .tofront{
            position: relative;
            z-index:2;
        }
    
        
        &::before {
            content:  url('../img/home/img-croissant.svg');
            position: absolute;
            z-index:1;
    
            right: -148px;
            bottom: -138px;
    
            @media (min-width: $screen-sm-min) {
                right:-90px;
                top:-90px;
                bottom: auto;
            }
        }
    
        h2 {
            margin-top: 0;
            margin-bottom: 24px;
        }
    
        .testimonial-item p{
            &::before{
                content: '« ';
            }
            &::after{
                content: ' »';
            }
        }
    
        .testimonial-customer {
            display: flex;
            align-items: center;
           
            .face {
                background-color: $color-font-medium;
                width: 64px;
                height: 64px;
                border-radius: 64px;
                margin-right: 24px;
            }
        }
        .slick-dots {
            text-align: left;
            bottom: -55px;
            li {
                width: 15px;
                button::before {
                    font-size: 36px;
                    color: $color-secondary;
                }
            }
        }
    }
}  

