
@use "../theme_variables" as theme;
@use "../vendor/bootstrap/bootstrap/variables" as bs;

html, body{
    font-family: theme.$main-font;
}

@mixin font-title {
    font-size: 40px;
    line-height: 1.6;
}

@mixin font-title-2 { 
    font-weight: bold;
    font-size: 48px;
    line-height: 64px;
}

@mixin font-title-3 {
    font-weight: bold;
    font-size: 44px;
    line-height: 56px;
}

@mixin font-title-4 {
    font-size: 40px;
    line-height: 52px;
    font-weight: bold;
}

@mixin font-title-5 {
    font-size: 30px;
    line-height: 40px;
    font-weight: bold;
}

@mixin font-title-6 {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
}

@mixin font-body {
    font-size: 20px;
    line-height: 32px;
    font-weight: 300;
}

@mixin font-body-2 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
}

@mixin font-body-3 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
}

@mixin font-subtitle {
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
}

@mixin font-subtitle-2{
    font-size: 20px;
    line-height: 28px;
    font-weight: bold;
}

@mixin font-button {
    font-size: 20px;
    line-height: 32px;
    font-weight: bold;
}

@mixin font-button {
    font-size: 20px;
    line-height: 32px;
    font-weight: bold;
}

@mixin font-button-2 { 
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
}

@mixin font-caption{
    font-size: 12px;
    line-height: 20px;
}

@mixin font-caption-2{
    font-size: 14px;
    line-height: 20px;
}

@mixin font-overline-2{
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.font-title {
    @include font-title();
}

.font-title-2 {
    @include font-title-4();
    @media (min-width: bs.$screen-sm-min) {
        @include font-title-2();
    }
}

.font-title-3 {
    @include font-title-5();
    @media (min-width: bs.$screen-sm-min) {
        @include font-title-3();
    }
}

.font-title-4 {
    @include font-title-6();
    @media (min-width: bs.$screen-sm-min) {
        @include font-title-4();
    }
}

.font-title-5 {
    @include font-title-6();
    @media (min-width: bs.$screen-sm-min) {
        @include font-title-5();
    }
}

.font-title-6{
    @include font-title-6();
}

.font-subtitle {
    @include font-body();
    @media (min-width: bs.$screen-sm-min) {
        @include font-subtitle();
    }
}

.font-subtitle-2 {
    @include font-subtitle-2();
}

.font-button {
    @include font-button();
}

.font-button-2{
    @include font-button-2();
}

.font-body {
    @include font-body-2();
    @media (min-width: bs.$screen-sm-min) {
        @include font-body();
    }
}

.font-body-2 {
    @include font-body-2();
}

.font-body-3 {
    @include font-body-3();
}


.font-overline-2{
    @include font-overline-2();
}

.font-bold {
    font-weight: bold;
}

.font-caption {
    @include font-caption();
}

.font-cpation-2 {
    @include font-caption-2();
}

.font-regular {
    font-weight: 300;
}

.font-primary {
    color: theme.$color-primary;
}

.font-secondary {
    color: theme.$color-secondary;
}

.font-error{
    color: theme.$color-error;
}

.font-success{
    color: theme.$color-success;
}

.font-dark{
    color: theme.$color-font-dark;
}

.font-medium{
    color: theme.$color-font-medium;
}

.font-light{
    color: theme.$color-font-light;
}

.font-alt{
    font-family: theme.$alt-font;;
}


.mb-12 {
    margin-bottom: 12px;
}

.mb-16{
    margin-bottom: 16px;
}

.mb-24 {
    margin-bottom: 24px !important;
}

.mb-32{
    margin-bottom: 32px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-150 {
    margin-bottom: 150px;
}

.mt-40 {
    margin-top: 40px;
}

.pl-0{
    padding-left:0 !important;
}

.pl-1 {
    padding-left: 0.5rem;
}

.pl-2 {
    padding-left: 1rem;
}

.pl-3 {
    padding-left: 1.5rem;
}

.pl-4 {
    padding-left: 2rem;
}

.pl-16 {
    padding-left: 16px;
}

.pr-1 {
    padding-right: 0.5rem;
}

.pr-2 {
    padding-right: 1rem;
}

.pr-3 {
    padding-right: 1.5rem;
}

.pr-4 {
    padding-right: 2rem;
}



.ml-1 {
    margin-left: 0.5rem;
}

.ml-2 {
    margin-left: 1rem;
}

.ml-3 {
    margin-left: 1.5rem;
}

.ml-4 {
    margin-left: 2rem;
}

.mr-1 {
    margin-right: 0.5rem;
}

.mr-2 {
    margin-right: 1rem;
}

.mr-3 {
    margin-right: 1.5rem;
}

.mr-4 {
    margin-right: 2rem;
}


.mt-1 {
    margin-top: 0.5rem;
}

.mt-2 {
    margin-top: 1rem;
}

.mt-3 {
    margin-top: 1.5rem;
}

.mt-4 {
    margin-top: 2rem;
}

.mb-1 {
    margin-bottom: 0.5rem;
}

.mb-2 {
    margin-bottom: 1rem;
}

.mb-3 {
    margin-bottom: 1.5rem;
}

.mb-4 {
    margin-bottom: 2rem;
}

.mt-24 {
    margin-top: 24px;
}


.content-block {
    @media (min-width: bs.$screen-sm-min) {
        padding-top: 56px;
        padding-bottom: 56px;
    }
    
    padding-top: 96px;
    padding-bottom: 96px;
}

.no-overflow{
    overflow: hidden;
}


.d-flex{
    display: flex;
}
.align-items-center {
    align-items: center;
}

.no-p{
    padding-left: 0;
    padding-right: 0;
}

.row.no-gutters {
    margin-right: 0;
    margin-left: 0; }
  
  // add > to target first child
  .row.no-gutters > [class^="col-"],
  .row.no-gutters > [class*=" col-"]{
    padding-right: 0;
    padding-left: 0; 
}

.text-md-right{
    @media (min-width: bs.$screen-md-min) {
        text-align:right;
    }
}

.flex-justify-end{
    justify-content: end;
}

.container-md {
    @media (min-width: bs.$screen-md-min) {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
}


.pill {
    background-color: theme.$color-primary;
    color: theme.$color-font-light;
    padding: 6px 12px;
    font-family: theme.$alt-font;
    font-size: 14px;
    line-height: 1.43;
    border-radius: 20px;
}


.how-item{

    margin-bottom: 24px;

    .figure{
        position:relative;

        img{
            width: 100%;
        }
        .pill {
            left: 21px;
            top: 24px;
            position: absolute;
            z-index: 2;
        }
    }
}

.hr-bike {
    display: flex;
    align-items: center;
    .line {
        opacity: 0.8;
        background-image: radial-gradient(theme.$color-secondary 1px, transparent 1px);
        background-size: 10px 10px;
        height: 10px;
        flex-grow: 10;
        margin-right: 15px;
        margin-top: 30px;

        &.primary{
            background-image: radial-gradient(theme.$color-primary 1px, transparent 1px);
        }
    }

    .bike{
        flex-grow: 0;
    }
}


li{
    list-style-type: none;
}

ul {
    display: block;
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;
}


.state-pill{
    padding: 7px 12px;
    border-radius: 20px;
    color: theme.$color-font-light;
    background-color: theme.$color-primary;
    display: inline-block;
    text-align:center;
    @include font-caption();
}

.state-30{
    background-color: theme.$color-success;
}

.state-29{
    background-color: theme.$color-secondary;
}

.state-24{
    background-color: theme.$color-tertiary-beta;
}

.state-23{
    background-color: theme.$color-secondary;
}

.state-22{
    background-color: theme.$color-primary;
}

.state-21{
    background-color: theme.$color-primary;
}
.state-20{
    background-color: theme.$color-primary;
}

.flex-direction-column {
    flex-direction: column;
}

.text-wrap {
    text-wrap: wrap;
}