
@use "./text" as text;
@use "../vendor/bootstrap/bootstrap/variables" as bs;
@use "../theme_variables" as theme;
#supplier {
    .item-card {
        img {
            height: 100%;
        }
    }
}
.item-card {
    width: 260px;
    height: 330px;
    overflow: hidden;
    border-radius: 30px;
    position: relative;
   

    &:hover{
        img {
            transform: scale(1.1);
        }
        
    }

    img {
        transition: transform 0.3s ease;
        object-fit: cover;
        width: 100%;
        z-index: 1;
        position: relative;
        display: block;
    }

    .text{
        position: absolute;
        bottom: 15px;
        left:15px;
        z-index: 3;

        h3 {
            color: theme.$color-font-light;
            @include text.font-title-6();
        }
    
        h4 {
            color: theme.$color-font-light;
            @include text.font-body-2();
        }
    }   

    a,.shadow {
        outline: none;
        position: absolute;
        content:"";
        height:100%;
        width:100%;
        top:0;
        left:0;
        z-index:2;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 61%, rgba(0, 0, 0, 0.53));
    }

    &.team-card{
        padding: 0;
        .text {

            bottom: 24px;
            left: 24px;

            h3{
                margin-bottom: 0;
            }
            h4{
                margin-bottom: 4px;
            }
            div{
                color: $color-font-light;
            }
        }
    }
}

.supplier-card {
    width: 100%;

    margin-bottom: 16px;
    height: 320px;
    @include text.font-title-5;

    @media (min-width: bs.$screen-md-min) {
        margin-bottom: 30px;
        height: 450px;
        @include text.font-title-6;
    }
}
