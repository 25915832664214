.container-gift {
    
    position: relative;

    &::before{
        content: ' ';
        background-image:url(../img/gift/bitmapv2.jpg) ;
        position: absolute;
        background-size: cover;
        background-position: center;
        z-index: 1;
        display: block;

        top: 60%;
        left:0;
        right: 0;
        bottom: 0;
       
        @media (min-width: $screen-sm-min) {
            top: 0;
            left: 25%;
        }

    }

    &::after{
        display: block;
        z-index: 2;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-image: linear-gradient(352deg, rgba(0, 0, 0, 0) 10%,rgba(0, 0, 0, 0.1) 9%, #16314b 33%);
        @media (min-width: $screen-sm-min) {
            background-image: linear-gradient(282deg, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.1) 9%, #16314b 57%);
        }     
    }
   
    &>.row{
        position: relative;
        z-index: 3;
    }

    padding-top: 56px;
    padding-bottom: 280px;

    @media (min-width: $screen-sm-min) {
        padding-top: 120px;
        padding-bottom: 128px;
    }

}

.container-gift-popup {
    
    padding-top: 20px;
    padding-bottom: 20px;

    &::before{
        background-image: none;
    }

    @media (max-width: $screen-sm-min) {
        &::after{
            background-image: none !important;
            background-color: #16314b;
        }
    }

    @media (min-width: $screen-sm-min) {

        &::before{
            content: ' ';
            background-image:url(../img/gift/bitmapv2.jpg) ;
            position: absolute;
            background-size: cover;
            background-position: center;
            z-index: 1;
            display: block;

            top: 60%;
            left:0;
            right: 0;
            bottom: 0;
        
            @media (min-width: $screen-sm-min) {
                top: 0;
                left: 25%;
            }
        }

        
    }
}

.container-gift-small{
    position: relative;
    padding-top: 64px;
    padding-bottom: 14px;
    text-align: center;

    background-color: #16314b;

    @media (min-width: $screen-sm-min) {
        padding-top: 24px;
        padding-bottom: 14px;
    }

    // &::before{
    //     content: ' ';
    //     background-image:url(../img/gift/bitmapv2.jpg) ;
    //     position: absolute;
    //     background-size: cover;
    //     background-position: center;
    //     z-index: 1;
    //     display: block;

    //     top: 60%;
    //     left:0;
    //     right: 0;
    //     bottom: 0;
       
    //     @media (min-width: $screen-sm-min) {
    //         top: 0;
    //         left: 25%;
    //     }

    // }

    // &::after{
    //     display: block;
    //     z-index: 2;
    //     position: absolute;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     top: 0;
    //     background-image: linear-gradient(352deg, rgba(0, 0, 0, 0) 10%,rgba(0, 0, 0, 0.1) 9%, #16314b 33%);
    //     @media (min-width: $screen-sm-min) {
    //         background-image: linear-gradient(282deg, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.1) 9%, #16314b 57%);
    //     }     
    // }

    // &>.row{
    //     position: relative;
    //     z-index: 3;
    // }
}