@import "../vendor/bootstrap/bootstrap/variables";

.ig-row {
    .ig-item {
        display: block;
        height: 240px;
        overflow: hidden;
        background-color: black;
        img {
            opacity: .79;
            height: 100%;
            object-fit: contain;
            transform-origin: center;
            transition: transform .3s ease;
            transform: scale(1.2);
            &:hover {
                transform: scale(1.32);
            }
        }

        background-size: auto, cover;
        background-position: center;
        transition: filter .3s ease;
        display:flex;
        align-items: center;
        justify-content: center;

        &:hover::after {
            content: url('../img/footer/ic-instagram.svg');
            position:absolute;
        }
    }
    
}

#footer {
    background: $color-font-dark;
    padding-top: 30px;
    color: $color-font-light;
    font-weight: 300;

    @media (min-width: $screen-md-min) {
        padding-top: 72px;
    }

    img.logo{
        transform: scale(0.70);
        transform-origin: top left;

        @media (min-width: $screen-sm-min) {
            transform: scale(1);
            margin-bottom: 24px;
        }
    }

    h3 {
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        margin-bottom: 12px;
        position: relative;

        @media (max-width: $screen-md-min) {
            &::after {
                content: url('../img/header/ic-drop-down.svg');
                position: absolute;
                right: 0;
                transition: transform 0.2s ease;
            }
            &.open {
                &::after {
                    transform: rotate(180deg);
                    transform-origin: center center;
                }
            }
        }
    }

    ul {
        
        margin-bottom: 32px;
        
        @media (max-width: $screen-md-min) {
            display: none;
            transform: scaleY(0);
            transition: opacity 0.2s ease;
            &.open {
                display: block;
                animation: growDown 300ms ease-in-out forwards;
                transform-origin: top center
            }

            @keyframes growDown {
                0% {
                    transform: scaleY(0)
                }
                80% {
                    transform: scaleY(1.1)
                }
                100% {
                    transform: scaleY(1)
                }
            }
        }

       

        li {
            margin-bottom: 12px;
            a {
                font-size: 16px;
                line-height: 24px;
                color: $color-font-light;
                font-weight: 300;

                &:hover{
                    color: $color-tertiary;
                }

                &.social{
                    margin-right: 24px;
                    &:hover{
                        svg path{
                            fill: $color-tertiary;
                          }   
                    }
                }
            }
            
            &.social-title{
                font-family: $alt-font;
                font-size: 18px;
            }
            &.social-block{
                display: flex;
                align-items: center;

                img{
                    margin-right: 24px;
                }
            }
        }
    }

    .copyright{
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        padding: 12px;

        @media (min-width: $screen-sm-min) {
            font-size: 16px;
            padding: 24px;
            margin-top: 80px;
        }
    }
}
